<template>
  <v-navigation-drawer
    width="250"
    fixed
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
  >
    <v-list-item class="pa-0" >
      <v-list-item-content class="pa-0">
        <v-list-item-title class="title d-flex align-center mb-0">
          <div class="v-navigation-drawer-brand pa-5 d-flex align-center" @click="moveTop">
            <img src="@/assets/tprlogo.png" class="w-90" />
          </div>

          <div
            class="drawer-toggler pa-5 cursor-pointer"
            :class="{ active: togglerActive }"
            @click="minifyDrawer"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line bg-default"></i>
              <i class="drawer-toggler-line bg-default"></i>
              <i class="drawer-toggler-line bg-default"></i>
            </div>
          </div>
        </v-list-item-title>
        <span
            class="text-caption font-weight-600 mx-5 mb-4 white-space-nowrap"
        >
          {{ this.companyName }}
        </span>
      </v-list-item-content>
    </v-list-item>

    <v-list nav dense>
      <v-list-item-group>
        <v-list-item
            v-for="(item, i) in itemsDashboard()"
            :key="i"
            link
            :to="item.link"
            class="pb-1 no-default-hover"
            :ripple="false"
            active-class=""
        >
          <v-list-item-icon>
            <v-icon class="ml-1 mr-1">{{item.icon}}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <h5 v-if="itemsMedia().length > 0"
          class="text-uppercase text-caption ls-0 font-weight-600 p-0 text-muted mt-6 mx-3 mb-4 d-none-mini white-space-nowrap"
      >
        メディア管理
      </h5>

      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in itemsMedia()"
          :key="i"
          link
          :to="item.link"
          class="pb-1 no-default-hover"
          :ripple="false"
          active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon class="ml-1 mr-1">{{item.icon}}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <h5
          class="text-uppercase text-caption ls-0 font-weight-600 p-0 text-muted mt-8 mx-3 mb-4 d-none-mini white-space-nowrap"
      >
        採用
      </h5>

      <v-list-item-group>
        <v-list-item
            v-for="(item, i) in itemsRecruit()"
            :key="i"
            link
            :to="item.link"
            class="pb-1 no-default-hover"
            :ripple="false"
            active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon class="ml-1 mr-1">{{item.icon}}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <h5 v-if="itemsSetting().length > 0"
          class="text-uppercase text-caption ls-0 font-weight-600 p-0 text-muted mt-8 mx-3 mb-4 d-none-mini white-space-nowrap"
      >
        設定
      </h5>

      <v-list-item-group>
        <v-list-item
            v-for="(item, i) in itemsSetting()"
            :key="i"
            link
            :to="item.link"
            class="pb-1 no-default-hover"
            :ripple="false"
            active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon class="ml-1 mr-1">{{item.icon}}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <div class="border-bottom mt-15 ma-3"></div>

      <h5
        class="text-uppercase text-caption ls-0 font-weight-600 p-0 text-muted mx-3 mb-4 d-none-mini white-space-nowrap"
      >
        ヘルプ
      </h5>

      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in itemsDocs"
          :key="i"
          link
          :href="item.link"
          class="pb-1 no-default-hover"
          :ripple="false"
          active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon class="ml-1 mr-1">{{item.icon}}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    mini: false,
    companyName: "",
    togglerActive: false,
    admin: 1,
    itemsDocs: [
      {
        icon: "mdi-account-question-outline",
        link: "mailto:triple.page@333-inc.com",
        title: "問い合わせ",
      },
    ],
  }),
  methods: {
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
    moveTop() {
      location.href = "/";
    },
    itemsDashboard() {
      if (this.getLoginUser().CompanyId === this.admin) {
        return [
          {
            icon: "fa-tv",
            link: "/" + this.$route.params.companyId + "/admin",
            title: "ダッシュボード",
          },
        ];
      }
      return [];
    },
    itemsRecruit() {
      return [
        {
          icon: "fa-address-card",
          link: "/" + this.$route.params.companyId + "/admin/candidates",
          title: "候補者",
        },
      ];
    },
    itemsSetting(){
      if (this.getLoginUser().CompanyId === this.admin) {
        return [
          {
            icon: "fa-store",
            link: "/" + this.$route.params.companyId + "/admin/stores",
            title: "店舗管理",
          },
          {
            icon: "fa-store", //TODO アイコン変える
            link: "/" + this.$route.params.companyId + "/admin/interviews",
            title: "インタビュー管理",
          },
          {
            icon: "fa-envelope-open-text",
            link: "/" + this.$route.params.companyId + "/admin/mail-templates",
            title: "メールテンプレート管理",
          },
          {
            icon: "fa-address-card",
            link: "/" + this.$route.params.companyId + "/admin/contacts",
            title: "連絡先管理",
          },
          {
            icon: "fa-tags",
            link: "/" + this.$route.params.companyId + "/admin/tag-groups",
            title: "タググループ管理"
          },
          {
            icon: "fa-link",
            link: "/" + this.$route.params.companyId + "/admin/company-mediums",
            title: "媒体連携"
          }
        ];
      }
      return [];
    },
    itemsMedia() {
      if (this.getLoginUser().CompanyId === this.admin) {
        return [
          {
            icon: "fa-file-signature",
            link: "/" + this.$route.params.companyId + "/admin/articles",
            title: "求人掲載一覧",
          },
          {
            icon: "fa-upload",
            link: "/" + this.$route.params.companyId + "/admin/articles-batch",
            title: "求人掲載一括アップロード",
          },
          {
            icon: "fa-file-signature",
            link: "/" + this.$route.params.companyId + "/admin/referral-articles",
            title: "リファラル専用記事一覧",
          },
          {
            icon: "fa-file-signature",
            link: "/" + this.$route.params.companyId + "/admin/alumni-articles",
            title: "アルムナイ専用記事一覧",
          },
          {
            icon: "fa-building",
            link: "/" + this.$route.params.companyId + "/admin/top-article",
            title: "求人トップ",
          },
        ]
      }
      return [];
    },
    ...mapGetters("loginUser", ["getLoginUser"]),
  },
  mounted() {
    if (this.$route.params.companyId) {
      axios.get("/companies/" + this.$route.params.companyId).then(res => {
        this.companyName = res.data.CompanyName;
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        } else {
          console.log(e.response)
          this.openSnackbarApiError();
        }
      }).finally(() => {

      });
    }
  }
};
</script>
