import Vue from "vue";
import VueRouter from "vue-router";
// import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthLayout from "../views/Login/AuthLayout";
import Index from "../views/Index";

import AdminDashboardLayout from "../views/Admin/AdminLayout";
import AdminLayout from "../views/Admin/AdminLayout";
import AdminDashboard from "../views/Admin/AdminDashboard";
import Companies from "../views/Admin/Company/Companies";
import CompanyDetail from "../views/Admin/Company/CompanyDetail";
import Users from "../views/Admin/User/Users";
import UserDetail from "../views/Admin/User/UserDetail";
import Tags from "../views/Admin/Tag/Tags";
import TagDetail from "../views/Admin/Tag/TagDetail";

import CompanyAdminLayout from "../views/CompanyAdmin/CompanyAdminLayout";
import CompanyAdminDashboard from "../views/CompanyAdmin/CompanyAdminDashboard";
import Stores from "../views/CompanyAdmin/Store/Stores";
import Contacts from "../views/CompanyAdmin/Contact/Contacts";
import Candidates from "../views/CompanyAdmin/Candidate/Candidates";
import Articles from "../views/CompanyAdmin/Article/Articles";
import ReferralArticles from "../views/CompanyAdmin/ReferralArticle/ReferralArticle";
import ReferralArticlesDetail from "../views/CompanyAdmin/ReferralArticle/ReferralArticleDetail";
import AlumniArticles from "../views/CompanyAdmin/AlumniArticle/AlumniArticle";
import AlumniArticlesDetail from "../views/CompanyAdmin/AlumniArticle/AlumniArticleDetail";
import StoreDetail from "../views/CompanyAdmin/Store/StoreDetail";
import ContactDetail from "../views/CompanyAdmin/Contact/ContactDetail";
import CandidateDetail from "../views/CompanyAdmin/Candidate/CandidateDetail";
import CandidateShow from "../views/CompanyAdmin/Candidate/CandidateShow";
import ArticleDetail from "../views/CompanyAdmin/Article/ArticleDetail";
import ArticleBulkUpdate from "../views/CompanyAdmin/Article/ArticleBulkUpdate";
import TopArticleDetail from "../views/CompanyAdmin/TopArticle/TopArticleDetail";
import MailTemplates from "../views/CompanyAdmin/MailTemplate/MailTemplates";
import MailTemplateDetail from "../views/CompanyAdmin/MailTemplate/MailTemplateDetail";
import TagGroups from "../views/CompanyAdmin/TagGroup/TagGroups";
import TagGroupDetail from "../views/CompanyAdmin/TagGroup/TagGroupDetail";
import Interviews from "../views/CompanyAdmin/Interview/Interviews";
import InterviewDetail from "../views/CompanyAdmin/Interview/InterviewDetail";
import CompanyMediums from "../views/CompanyAdmin/CompanyMedium/CompanyMediums"
import CompanyMediumDetail from "../views/CompanyAdmin/CompanyMedium/CompanyMediumDetail.vue";

// Dashboard pages
// const Dashboard = () => import("../views/Dashboard/CompanyAdminDashboard.vue");
// const AlternativeDashboard = () =>
//   import("../views/Dashboard/AlternativeDashboard.vue");
// const Calendar = () => import("../views/Dashboard/Calendar.vue");
// const Charts = () => import("../views/Dashboard/Charts.vue");
// const Widgets = () => import("../views/Dashboard/Widgets.vue");

// Components pages
// const Buttons = () => import("../views/Components/Buttons.vue");
// const Grid = () => import("../views/Components/Grid.vue");
// const Icons = () => import("../views/Components/Icons.vue");
// const Typography = () => import("../views/Components/Typography.vue");
// const Cards = () => import("../views/Components/Cards.vue");
// const Notifications = () => import("../views/Components/Notifications.vue");

// Maps pages
// const GoogleMaps = () => import("../views/Maps/Google.vue");
// const VectorMaps = () => import("../views/Maps/Vector.vue");

// Tables pages
// const RegularTables = () => import("../views/Tables/RegularTables.vue");
// const SortableTables = () => import("../views/Tables/SortableTables.vue");
// const PaginatedTables = () => import("../views/Tables/PaginatedTables.vue");

// Forms pages
// const FormElements = () => import("../views/Forms/FormElements.vue");
// const FormComponents = () => import("../views/Forms/FormComponents.vue");
// const FormValidation = () => import("../views/Forms/FormValidation.vue");

// Pages
// const Pricing = () =>
//   import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const Login = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Login/Login.vue");
// const Register = () =>
//   import(/* webpackChunkName: "pages" */ "@/views/Pages/Register.vue");
// const Lock = () =>
//   import(/* webpackChunkName: "pages" */ "@/views/Pages/Lock.vue");
// const Profile = () =>
//   import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile.vue");
// const Timeline = () =>
//   import(/* webpackChunkName: "pages" */ "@/views/Pages/Timeline.vue");

Vue.use(VueRouter);

// let componentsMenu = {
//   path: "/components",
//   component: DashboardLayout,
//   redirect: "/components/buttons",
//   name: "Components",
//   children: [
//     {
//       path: "buttons",
//       name: "Buttons",
//       components: { default: Buttons },
//       meta: {
//         groupName: "Components",
//       },
//     },
//     {
//       path: "cards",
//       name: "Cards",
//       components: { default: Cards },
//       meta: {
//         groupName: "Components",
//       },
//     },
//     {
//       path: "grid",
//       name: "Grid",
//       components: { default: Grid },
//       meta: {
//         groupName: "Components",
//       },
//     },
//     {
//       path: "notifications",
//       name: "Notifications",
//       components: { default: Notifications },
//       meta: {
//         groupName: "Components",
//       },
//     },
//     {
//       path: "icons",
//       name: "Icons",
//       components: { default: Icons },
//       meta: {
//         groupName: "Components",
//       },
//     },
//     {
//       path: "typography",
//       name: "Typography",
//       components: { default: Typography },
//       meta: {
//         groupName: "Components",
//       },
//     },
//   ],
// };

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
  ],
};
let adminPages = {
  path: "/admin",
  component: AdminLayout,
  name: "Admin",
  children: [
    {
      path: "/",
      name: "Dashboard",
      component: AdminDashboard,
      meta: {
        groupName: "ダッシュボード",
        groupPath: "/admin",
        displayName: "ホーム",
        path: "/admin"
      }
    },
    {
      path: "/admin/companies",
      name: "Companies",
      component: Companies,
      meta: {
        groupName: "企業管理",
        groupPath: "companies",
        displayName: "企業一覧",
        path: "companies"
      }
    },
    {
      path: "/admin/companies/create",
      name: "CompaniesCreate",
      component: CompanyDetail,
      meta: {
        groupName: "企業管理",
        groupPath: "../companies",
        displayName: "企業作成",
        path: "create"
      }
    },
    {
      path: "/admin/companies/:companyId",
      name: "CompaniesCreate",
      component: CompanyDetail,
      meta: {
        groupName: "企業管理",
        groupPath: "../companies",
        displayName: "企業更新",
        path: ""
      }
    },
    {
      path: "/admin/users",
      name: "Users",
      component: Users,
      meta: {
        groupName: "ユーザー管理",
        groupPath: "users",
        displayName: "ユーザー一覧",
        path: "users"
      }
    },
    {
      path: "/admin/users/create",
      name: "UsersCreate",
      component: UserDetail,
      meta: {
        groupName: "ユーザー管理",
        groupPath: "../users",
        displayName: "ユーザー作成",
        path: "create"
      }
    },
    {
      path: "/admin/users/:userId",
      name: "UsersCreate",
      component: UserDetail,
      meta: {
        groupName: "ユーザー管理",
        groupPath: "../users",
        displayName: "ユーザー更新",
        path: ""
      }
    },
    {
      path: "/admin/tags",
      name: "Tags",
      component: Tags,
      meta: {
        groupName: "タグ管理",
        groupPath: "tags",
        displayName: "タグ一覧",
        path: "tags"
      }
    },
    {
      path: "/admin/tags/create",
      name: "TagsCreate",
      component: TagDetail,
      meta: {
        groupName: "タグ管理",
        groupPath: "../tags",
        displayName: "タグ作成",
        path: "create"
      }
    },
    {
      path: "/admin/tags/:tagId",
      name: "TagsUpdate",
      component: TagDetail,
      meta: {
        groupName: "タグ管理",
        groupPath: "../tags",
        displayName: "タグ更新",
        path: ""
      }
    },
  ]
}

let companyAdminPages = {
  path: "/:companyId/admin",
  component: CompanyAdminLayout,
  name: "CompanyAdmin",
  children: [
    {
      path: "/",
      name: "CompanyAdminDashboard",
      component: CompanyAdminDashboard,
      meta: {
        groupName: "ダッシュボード",
        displayName: "ホーム",
        path: "admin"
      },
    },
    {
      path: "/:companyId/admin/stores",
      name: "Stores",
      component: Stores,
      meta: {
        groupName: "店舗管理",
        groupPath: "stores",
        displayName: "店舗一覧",
        path: "stores"
      }
    },
    {
      path: "/:companyId/admin/stores/create",
      name: "StoreCreate",
      component: StoreDetail,
      meta: {
        groupName: "店舗管理",
        groupPath: "../stores",
        displayName: "店舗作成",
        path: "create"
      }
    },
    {
      path: "/:companyId/admin/stores/:storeId",
      name: "StoreUpdate",
      component: StoreDetail,
      meta: {
        groupName: "店舗管理",
        groupPath: "../stores",
        displayName: "店舗更新",
        path: ""
      }
    },
    {
      path: "/:companyId/admin/candidates",
      name: "Candidates",
      component: Candidates,
      meta: {
        groupName: "候補者管理",
        groupPath: "candidates",
        displayName: "候補者一覧",
        path: "candidates"
      }
    },
    {
      path: "/:companyId/admin/candidates/create",
      name: "CandidateCreate",
      component: CandidateDetail,
      meta: {
        groupName: "候補者管理",
        groupPath: "../candidates",
        displayName: "候補者作成",
        path: "create"
      }
    },
    {
      path: "/:companyId/admin/candidates/:candidateId",
      name: "CandidateUpdate",
      component: CandidateDetail,
      meta: {
        groupName: "候補者管理",
        groupPath: "../candidates",
        displayName: "候補者更新",
        path: ""
      }
    },
    {
      path: "/:companyId/admin/candidates-show/:candidateId",
      name: "CandidateShow",
      component: CandidateShow,
      meta: {
        groupName: "候補者管理",
        groupPath: "../candidates",
        displayName: "候補者詳細",
        path: ""
      }
    },
    {
      path: "/:companyId/admin/contacts",
      name: "Contacts",
      component: Contacts,
      meta: {
        groupName: "連絡先管理",
        groupPath: "contacts",
        displayName: "連絡先一覧",
        path: "contacts"
      }
    },
    {
      path: "/:companyId/admin/contacts/create",
      name: "ContactCreate",
      component: ContactDetail,
      meta: {
        groupName: "連絡先管理",
        groupPath: "../contacts",
        displayName: "連絡先作成",
        path: "create"
      }
    },
    {
      path: "/:companyId/admin/contacts/:contactId",
      name: "ContactUpdate",
      component: ContactDetail,
      meta: {
        groupName: "連絡先管理",
        groupPath: "../contacts",
        displayName: "連絡先更新",
        path: ""
      }
    },
    {
      path: "/:companyId/admin/articles",
      name: "Articles",
      component: Articles,
      meta: {
        groupName: "記事管理",
        groupPath: "articles",
        displayName: "記事一覧",
        path: "articles"
      }
    },
    {
      path: "/:companyId/admin/articles/create",
      name: "ArticlesCreate",
      component: ArticleDetail,
      meta: {
        groupName: "記事管理",
        groupPath: "../articles",
        displayName: "記事作成",
        path: "create"
      }
    },
    {
      path: "/:companyId/admin/articles-batch",
      name: "ArticleBulkUpdate",
      component: ArticleBulkUpdate,
      meta: {
        groupName: "メディア管理",
        displayName: "求人掲載一括アップロード",
        path: "./articles-batch"
      }
    },
    {
      path: "/:companyId/admin/articles/:articleId",
      name: "ArticleUpdate",
      component: ArticleDetail,
      meta: {
        groupName: "記事管理",
        groupPath: "../articles",
        displayName: "記事更新",
        path: ""
      }
    },
    {
      path: "/:companyId/admin/top-article",
      name: "TopArticleDetail",
      component: TopArticleDetail,
      meta: {
        groupName: "求人トップ管理",
        displayName: "求人トップ更新",
        path: ""
      }
    },
    {
      path: "/:companyId/admin/referral-articles",
      name: "ReferralArticles",
      component: ReferralArticles,
      meta: {
        groupName: "リファラル記事管理",
        groupPath: "referral-articles",
        displayName: "リファラル記事一覧",
        path: "referral-articles"
      }
    },
    {
      path: "/:companyId/admin/referral-articles/create",
      name: "ReferralArticlesCreate",
      component: ReferralArticlesDetail,
      meta: {
        groupName: "リファラル記事管理",
        groupPath: "../referral-articles",
        displayName: "リファラル記事の作成",
        path: "create"
      }
    },
    {
      path: "/:companyId/admin/referral-articles/:articleId",
      name: "ArticleUpdate",
      component: ReferralArticlesDetail,
      meta: {
        groupName: "リファラル記事管理",
        groupPath: "../referral-articles",
        displayName: "リファラル記事の更新",
        path: ""
      }
    },
    {
      path: "/:companyId/admin/alumni-articles",
      name: "AlumniArticles",
      component: AlumniArticles,
      meta: {
        groupName: "アルムナイ記事管理",
        groupPath: "alumni-articles",
        displayName: "アルムナイ記事一覧",
        path: "alumni-articles"
      }
    },
    {
      path: "/:companyId/admin/alumni-articles/create",
      name: "AlumniArticlesCreate",
      component: AlumniArticlesDetail,
      meta: {
        groupName: "アルムナイ記事管理",
        groupPath: "../alumni-articles",
        displayName: "アルムナイ記事を作成する",
        path: "create"
      }
    },
    {
      path: "/:companyId/admin/alumni-articles/:articleId",
      name: "AlumniArticlesUpdate",
      component: AlumniArticlesDetail,
      meta: {
        groupName: "アルムナイ記事管理",
        groupPath: "../alumni-articles",
        displayName: "アルムナイ記事更新",
        path: ""
      }
    },
    {
      path: "/:companyId/admin/mail-templates",
      name: "MailTemplates",
      component: MailTemplates,
      meta: {
        groupName: "メールテンプレート管理",
        groupPath: "mail-templates",
        displayName: "メールテンプレート一覧",
        path: "mail-templates"
      }
    },
    {
      path: "/:companyId/admin/mail-templates/create",
      name: "MailTemplateCreate",
      component: MailTemplateDetail,
      meta: {
        groupName: "メールテンプレート管理",
        groupPath: "../mail-templates",
        displayName: "メールテンプレート作成",
        path: "create"
      }
    },
    {
      path: "/:companyId/admin/mail-templates/:mailTemplateId",
      name: "MailTemplateUpdate",
      component: MailTemplateDetail,
      meta: {
        groupName: "メールテンプレート管理",
        groupPath: "../mail-templates",
        displayName: "メールテンプレート更新",
        path: ""
      }
    },
    {
      path: "/:companyId/admin/tag-groups",
      name: "TagGroups",
      component: TagGroups,
      meta: {
        groupName: "タググループ管理",
        groupPath: "tag-groups",
        displayName: "タググループ一覧",
        path: "tag-groups"
      }
    },
    {
      path: "/:companyId/admin/tag-groups/create",
      name: "TagGroupsCreate",
      component: TagGroupDetail,
      meta: {
        groupName: "タググループ管理",
        groupPath: "../tag-groups",
        displayName: "タググループ作成",
        path: ""
      }
    },
    {
      path: "/:companyId/admin/tag-groups/:tagGroupId",
      name: "TagGroupsUpdate",
      component: TagGroupDetail,
      meta: {
        groupName: "タググループ管理",
        groupPath: "../tag-groups",
        displayName: "タググループ更新",
        path: ""
      }
    },
    {
      path: "/:companyId/admin/interviews",
      name: "Interviews",
      component: Interviews,
      meta: {
        groupName: "インタビュー管理",
        groupPath: "interviews",
        displayName: "インタビュー一覧",
        path: "interviews"
      }
    },
    {
      path: "/:companyId/admin/interviews/create",
      name: "InterviewDetailCreate",
      component: InterviewDetail,
      meta: {
        groupName: "インタビュー管理",
        groupPath: "../interviews",
        displayName: "インタビューの作成",
        path: "create"
      }
    },
    {
      path: "/:companyId/admin/interviews/:interviewsId",
      name: "InterviewUpdate",
      component: InterviewDetail,
      meta: {
        groupName: "インタビュー管理",
        groupPath: "../interviews",
        displayName: "インタビューの更新",
      }
    },
    {
      path: "/:companyId/admin/company-mediums",
      name: "CompanyMediums",
      component: CompanyMediums,
      meta: {
        groupName: "媒体連携",
        groupPath: "company-mediums",
        displayName: "媒体連携一覧",
        path: "company-mediums"
      }
    },
    {
      path: "/:companyId/admin/company-mediums/create",
      name: "CompanyMediumsCreate",
      component: CompanyMediumDetail,
      meta: {
        groupName: "媒体連携",
        groupPath: "../company-mediums",
        displayName: "媒体連携追加",
        path: ""
      }
    },
    {
      path: "/:companyId/admin/company-mediums/:companyMediumId",
      name: "CompanyMediumsUpdate",
      component: CompanyMediumDetail,
      meta: {
        groupName: "媒体連携",
        groupPath: "../company-mediums",
        displayName: "媒体連携更新",
        path: ""
      }
    },
  ]
}

// let mapsMenu = {
//   path: "/maps",
//   component: DashboardLayout,
//   redirect: "/maps/google",
//   name: "Maps",
//   children: [
//     {
//       path: "/maps/google",
//       name: "Google",
//       components: { default: GoogleMaps },
//       meta: {
//         groupName: "Maps",
//       },
//     },
//     {
//       path: "/maps/vector",
//       name: "Vector",
//       components: { default: VectorMaps },
//       meta: {
//         groupName: "Maps",
//       },
//     },
//   ],
// };
//
// let formsMenu = {
//   path: "/forms",
//   component: DashboardLayout,
//   redirect: "/forms/elements",
//   name: "Forms",
//   children: [
//     {
//       path: "elements",
//       name: "Elements",
//       components: { default: FormElements },
//       meta: {
//         groupName: "Forms",
//       },
//     },
//     {
//       path: "components",
//       name: "Components",
//       components: { default: FormComponents },
//       meta: {
//         groupName: "Forms",
//       },
//     },
//     {
//       path: "validation",
//       name: "Validation",
//       components: { default: FormValidation },
//       meta: {
//         groupName: "Forms",
//       },
//     },
//   ],
// };
//
// let tablesMenu = {
//   path: "/tables",
//   component: DashboardLayout,
//   redirect: "/tables/regular",
//   name: "Tables menu",
//   children: [
//     {
//       path: "regular",
//       name: "Regular",
//       components: { default: RegularTables },
//       meta: {
//         groupName: "Tables",
//       },
//     },
//     {
//       path: "sortable",
//       name: "Sortable",
//       components: { default: SortableTables },
//       meta: {
//         groupName: "Tables",
//       },
//     },
//     {
//       path: "paginated",
//       name: "Paginated",
//       components: { default: PaginatedTables },
//       meta: {
//         groupName: "Tables",
//       },
//     },
//   ],
// };

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    // redirect: "/dashboard",
    // component: DashboardLayout,
    children: [
      // {
      //   path: "dashboard",
      //   name: "Dashboard",
      //   component: Dashboard,
      //   meta: {
      //     groupName: "Dashboards",
      //   },
      // },
      // {
      //   path: "/alternative",
      //   name: "Alternative",
      //   component: AlternativeDashboard,
      //   meta: {
      //     groupName: "Dashboards",
      //   },
      // },
      // {
      //   path: "/profile",
      //   name: "Profile",
      //   components: { default: Profile },
      //   meta: {
      //     groupName: "Components",
      //   },
      // },
      // {
      //   path: "/timeline",
      //   name: "Timeline",
      //   components: { default: Timeline },
      //   meta: {
      //     groupName: "Components",
      //   },
      // },
      // {
      //   path: "calendar",
      //   name: "Calendar",
      //   component: Calendar,
      //   meta: {
      //     groupName: "Components",
      //   },
      // },
      // {
      //   path: "charts",
      //   name: "Charts",
      //   component: Charts,
      //   meta: {
      //     groupName: "Components",
      //   },
      // },
      // {
      //   path: "widgets",
      //   name: "Widgets",
      //   component: Widgets,
      //   meta: {
      //     groupName: "Components",
      //   },
      // },
    ],
  },
  // componentsMenu,
  authPages,
  companyAdminPages,
  adminPages,
  // mapsMenu,
  // tablesMenu,
  // formsMenu,
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
