var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"py-4 px-2 toolbar-content-padding-y-none",class:{ 'border-bottom': !_vm.hasBg, 'border-bottom-dark': _vm.hasBg },attrs:{"color":_vm.background,"dark":"","height":"auto","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}}),_c('v-col',{staticClass:"text-right py-0 my-0",attrs:{"cols":"12","sm":"6"}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-btn',{staticClass:"font-weight-600 text-capitalize drawer-toggler py-3 px-6 rounded-sm",class:{
          'btn-dark-toggler-hover': !_vm.hasBg,
          'btn-toggler-hover': _vm.hasBg,
          active: _vm.togglerActive,
        },attrs:{"elevation":"0","ripple":false,"height":"43","color":"transparent"},on:{"click":_vm.drawerClose}},[_c('div',{staticClass:"drawer-toggler-inner"},[_c('i',{staticClass:"drawer-toggler-line",class:{ 'bg-white': _vm.hasBg }}),_c('i',{staticClass:"drawer-toggler-line",class:{ 'bg-white': _vm.hasBg }}),_c('i',{staticClass:"drawer-toggler-line",class:{ 'bg-white': _vm.hasBg }})])]):_vm._e(),_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-x":"","offset-y":"","right":"","max-width":"196"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ls-0 text-capitalize pe-0",attrs:{"depressed":"","ripple":false,"color":"transparent"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.getLoginName))]),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"black"}},[_vm._v("fa-caret-down")])],1)]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"min-height-auto",attrs:{"link":"","ripple":false},on:{"click":_vm.appLogout}},[_c('v-list-item-avatar',{staticClass:"my-0 me-5",attrs:{"width":"15","min-width":"15","height":"37"}},[_c('v-icon',{staticClass:"text-darker"},[_vm._v("ni-user-run")])],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"text-darker"},[_vm._v("ログアウト")])],1),_c('div',{staticClass:"border-bottom"})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }