<template>
  <v-app>
    <drawer :drawer="drawer"></drawer>
    <v-main>
      <div
          @click="drawer = false"
          v-if="drawer"
          class="position-absolute drawer-state"
      ></div>
      <app-bar
          v-if="$route.name != 'Alternative' && $route.name != 'Profile'"
          background="bg-primary"
          has-bg
          @drawer-toggle="drawer = $event"
          :toggle-active="drawer"
      ></app-bar>
      <app-bar
          v-else-if="$route.name == 'Profile'"
          background="bg-default"
          has-bg
          @drawer-toggle="drawer = $event"
          :toggle-active="drawer"
      ></app-bar>
      <app-bar
          v-else
          background="transparent"
          linkColor="rgba(0,0,0,.6)"
          @drawer-toggle="drawer = $event"
          :toggle-active="drawer"
      ></app-bar>
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <router-view></router-view>
      </fade-transition>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </v-main>
  </v-app>
</template>
<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

// import DashboardNavbar from './DashboardNavbar.vue';
import { FadeTransition } from "vue2-transitions";
import Drawer from "@/components/Drawer.vue";
import AppBar from "@/components/AppBar.vue";
import ContentFooter from "@/components/Footer.vue";
import axios from "axios";

export default {
  components: {
    ContentFooter,
    FadeTransition,
    Drawer,
    AppBar,
  },
  data() {
    return {
      drawer: null,
      pageTitle: null,
      startTime: null,
      previousRoute: null
    };
  },
  created() {
    // 初回ロード時のタイムスタンプを取得
    this.startTime = new Date().getTime();

    // ページを離れるときのイベントリスナーを追加
    window.addEventListener('beforeunload', () => this.saveTimeSpent(true));

    this.pageTitle = this.$route.meta.displayName;
  },
  beforeDestroy() {
    // beforeDestroyフックでイベントリスナーを削除
    window.removeEventListener('beforeunload', this.saveTimeSpent);
  },
  watch: {
    '$route'() {
      // URLが変わったときの処理
      this.saveTimeSpent();
      this.startTime = new Date().getTime();

      this.pageTitle = this.$route.meta.displayName;
    }
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    async saveTimeSpent() {
      if (this.startTime) {
        let endTime = new Date().getTime();
        let timeSpent = endTime - this.startTime;

        // ミリ秒を秒に変換
        timeSpent = Math.round(timeSpent / 1000);

        if(30 <= Number(timeSpent) <= 28800) { 
          try {
            // サーバーにデータを非同期リクエストで送信
            await axios.post("/page-logs", {
              CompanyId: Number(this.$route.params.companyId),
              Title: this.pageTitle,
              TimeSpent: Number(timeSpent),
            });
          } catch (error) {
            console.error('Error saving time spent:', error);
          }
        }
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
