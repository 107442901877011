<template>
  <div>
    <header-top-dashboard primary>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <Overlay :loading="getLoading"/>
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                <span v-show="!isUpdate">店舗作成</span>
                <span v-show="isUpdate">店舗更新</span>
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="12" md="8">
                  <p class="font-size-root text-body font-weight-thin" v-show="!isUpdate">
                    新しい店舗を登録します。<br>
                    GoogleMapのURLの埋め込み方法は<a href="https://www.design-inc.jp/contents/2017/04/google-map.php"
                                                     target="_blank">こちら</a>をご覧ください。<br>
                  </p>
                  <p class="font-size-root text-body font-weight-thin" v-show="isUpdate">
                    店舗を更新します。<br>
                    GoogleMapのURLの埋め込み方法は<a href="https://www.design-inc.jp/contents/2017/04/google-map.php"
                                                     target="_blank">こちら</a>をご覧ください。<br>
                  </p>
                </v-col>
              </v-row>
              <div class="border-bottom my-6"></div>

              <v-form class="mt-13">
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >店舗ID</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="StoreId"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>店舗名</label
                    >
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="例）トリプルスリー武蔵小杉店"
                        v-model="StoreName"
                        :error-messages="storeNameErrors"
                        :counter="255"
                        required
                        @input="$v.StoreName.$touch()"
                        @blur="$v.StoreName.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >席数</label
                    >
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="例）60席"
                        v-model="NumberOfSeats"
                        :error-messages="numberOfSeatsErrors"
                        :counter="255"
                        @input="$v.NumberOfSeats.$touch()"
                        @blur="$v.NumberOfSeats.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >業態</label
                    >
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="焼肉"
                        v-model="TypeOfBusiness"
                        :error-messages="typeOfBusinessErrors"
                        :counter="255"
                        @input="$v.TypeOfBusiness.$touch()"
                        @blur="$v.TypeOfBusiness.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>都道府県</label
                    >
                    <v-select
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        v-model="Pref"
                        :items="PREF_ITEMS"
                        @input="$v.Pref.$touch()"
                        @blur="$v.Pref.$touch()"
                        :error-messages="prefErrors"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>住所</label
                    >
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder='例）川崎市武蔵小杉1-1'
                        v-model="WorkLocation"
                        :error-messages="workLocationErrors"
                        required
                        :counter="255"
                        @input="$v.WorkLocation.$touch()"
                        @blur="$v.WorkLocation.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >最寄り駅</label
                    >
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder='例）武蔵小杉駅から徒歩5分'
                        v-model="NearestStation"
                        :counter="255"
                        :error-messages="nearestStationErrors"
                        @input="$v.NearestStation.$touch()"
                        @blur="$v.NearestStation.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >GoogleMap埋め込みURL</label
                    >
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder='例）<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25936.72609718731!2d139.739136!3d35.650135399999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188bbd9009ec09%3A0x481a93f0d2a409dd!2z5p2x5Lqs44K_44Ov44O8!5e0!3m2!1sja!2sjp!4v1648653716068!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
                        v-model="EmbedHtmlForGoogleMap"
                        :error-messages="embedHtmlForGoogleMapErrors"
                        :counter="2000"
                        @input="$v.EmbedHtmlForGoogleMap.$touch()"
                        @blur="$v.EmbedHtmlForGoogleMap.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <div>
                      <label
                          class="label-color font-weight-600 d-block"
                      >店舗ロゴファイル名</label>
                      <v-input
                          :error-messages="storeLogoFilenameErrors"
                      >
                        <v-file-input
                            type="file"
                            outlined
                            class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                            :placeholder="isUpdate && StoreLogoFilename ? '' :'例）image'"
                            accept="image/*"
                            hide-details
                            @change="(e) => uploadFile(e)"
                            @input="$v.StoreLogoFileUrl.$touch()"
                            @blur="$v.StoreLogoFileUrl.$touch()"
                        >
                          <template v-if="isUpdate && StoreLogoFilename && StoreLogoFile.entries().next().done"
                                    #prepend-inner>
                            {{ StoreLogoFilename }}
                          </template>
                        </v-file-input>
                      </v-input>
                    </div>
                    <div class="d-flex justify-center mb-2">
                      <img :src="StoreLogoFileUrl" alt="Uploaded Image" v-if="StoreLogoFileUrl" class="image-styles"/>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >店舗グループ</label
                    >
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="例）triple_page"
                        v-model="StoreGroup"
                        :error-messages="storeGroupErrors"
                        :counter="45"
                        @input="$v.StoreGroup.$touch()"
                        @blur="$v.StoreGroup.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >最終更新者</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="UpdatedByName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >最終更新日</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="UpdatedAt"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <div class="text-right">
                  <v-btn v-show="!isUpdate"
                         @click="submitCreate"
                         elevation="0"
                         :ripple="false"
                         height="43"
                         class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                         color="green"
                         :loading="loading"
                         large
                  >作成
                  </v-btn
                  >
                  <v-btn v-show="isUpdate"
                         @click="submitUpdate"
                         elevation="0"
                         :ripple="false"
                         height="43"
                         class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                         color="green"
                         :loading="loading"
                         large
                  >更新
                  </v-btn
                  >
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import {mapActions} from "vuex";
import {validationMixin} from "vuelidate";
import {required, maxLength, numeric} from "vuelidate/lib/validators";
import HeaderTopDashboard from "../../../components/HeaderTopDashboard";
import Overlay from '../../../components/Overlay.vue';
import dayjs from "dayjs";

export default {
  name: "StoreDetail",
  components: {
    HeaderTopDashboard,
    Overlay
  },
  created() {
    this.PREF_ITEMS = [
      "北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県",
      "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県",
      "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県",
      "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県",
      "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県",
      "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県",
    ];
  },
  data() {
    return {
      StoreId: "",
      StoreName: "",
      NumberOfSeats: "",
      TypeOfBusiness: "",
      Pref: "東京都",
      WorkLocation: "",
      NearestStation: "",
      EmbedHtmlForGoogleMap: "",
      StoreLogoFilename: "",
      StoreLogoFileUrl: "",
      StoreLogoFile: new FormData(),
      StoreGroup: "",
      UpdatedByName: "",
      UpdatedAt: "",
      loading: false,
      getLoading: false,
      isUpdate: false,
      CompanyDomain: ""
    }
  },
  mixins: [validationMixin],
  methods: {
    async uploadFile(file) {
      if (file) {
        const storeLogoFileUrl = URL.createObjectURL(file);
        this.StoreLogoFileUrl = storeLogoFileUrl;
        this.StoreLogoFilename = file.name;
        this.StoreLogoFile = new FormData();
        this.StoreLogoFile.append('StoreLogoFile', file);
      } else if (!file) {
        this.StoreLogoFileUrl = "";
        this.StoreLogoFilename = "",
        this.StoreLogoFile = "";
      }
    },
    async uploadMedia() {
      try {
        this.StoreLogoFile.append("CompanyId", this.$route.params.companyId);
        this.StoreLogoFile.append("CompanyDomain", this.CompanyDomain);
        if(this.StoreLogoFile.get("StoreLogoFile")){
          await axios.post("/media/upload", this.StoreLogoFile);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async submitCreate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('invalid');
        return;
      }
      this.loading = true;
      // await this.uploadMedia();
      axios.post("/stores", {
        StoreName: this.StoreName,
        CompanyId: Number(this.$route.params.companyId),
        NumberOfSeats: this.NumberOfSeats,
        TypeOfBusiness: this.TypeOfBusiness,
        Pref: this.Pref,
        WorkLocation: this.WorkLocation,
        NearestStation: this.NearestStation,
        EmbedHtmlForGoogleMap: this.EmbedHtmlForGoogleMap,
        StoreLogoFilename: this.StoreLogoFilename,
        StoreGroup: this.StoreGroup,
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({name: "Stores"});
      }).catch(e => {
        const res = e.response;
        if (res && res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    async submitUpdate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      await this.uploadMedia();
      axios.put("/stores/" + this.StoreId, {
        StoreId: this.StoreId,
        StoreName: this.StoreName,
        CompanyId: Number(this.$route.params.companyId),
        NumberOfSeats: this.NumberOfSeats,
        TypeOfBusiness: this.TypeOfBusiness,
        Pref: this.Pref,
        WorkLocation: this.WorkLocation,
        NearestStation: this.NearestStation,
        EmbedHtmlForGoogleMap: this.EmbedHtmlForGoogleMap,
        StoreLogoFilename: this.StoreLogoFilename,
        StoreGroup: this.StoreGroup,
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({name: "Stores"});
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    ...mapActions("snackbar", ["openSnackbarSuccess", "openSnackbarApiError", "openSnackbarValidationError"]),
  },
  validations: {
    StoreName: {required, maxLength: maxLength(255)},
    NumberOfSeats: {maxLength: maxLength(255)},
    TypeOfBusiness: {maxLength: maxLength(255)},
    Pref: {required, maxLength: maxLength(4)},
    WorkLocation: {required, maxLength: maxLength(255)},
    NearestStation: { maxLength: maxLength(255)},
    EmbedHtmlForGoogleMap: {maxLength: maxLength(2000)},
    StoreLogoFileUrl: {},
    StoreGroup: {maxLength: maxLength(45)},
  },
  computed: {
    storeNameErrors() {
      const errors = [];
      if (!this.$v.StoreName.$dirty) return errors;
      !this.$v.StoreName.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.StoreName.required && errors.push("入力必須項目です");
      return errors;
    },
    numberOfSeatsErrors() {
      const errors = [];
      if (!this.$v.NumberOfSeats.$dirty) return errors;
      !this.$v.NumberOfSeats.maxLength && errors.push("255文字以内で入力してください");
      //!this.$v.NumberOfSeats.required && errors.push("入力必須項目です");
      return errors;
    },
    typeOfBusinessErrors() {
      const errors = [];
      if (!this.$v.TypeOfBusiness.$dirty) return errors;
      !this.$v.TypeOfBusiness.maxLength && errors.push("255文字以内で入力してください");
      //!this.$v.TypeOfBusiness.required && errors.push("入力必須項目です");
      return errors;
    },
    prefErrors() {
      const errors = [];
      if (!this.$v.Pref.$dirty) return errors;
      !this.$v.Pref.maxLength && errors.push("4文字以内で入力してください");
      !this.$v.Pref.required && errors.push("入力必須項目です");
      return errors;
    },
    workLocationErrors() {
      const errors = [];
      if (!this.$v.WorkLocation.$dirty) return errors;
      !this.$v.WorkLocation.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.WorkLocation.required && errors.push("入力必須項目です");
      return errors;
    },
    nearestStationErrors() {
      const errors = [];
      if (!this.$v.NearestStation.$dirty) return errors;
      !this.$v.NearestStation.maxLength && errors.push("255文字以内で入力してください");
      //!this.$v.NearestStation.required && errors.push("入力必須項目です");
      return errors;
    },
    embedHtmlForGoogleMapErrors() {
      const errors = [];
      if (!this.$v.EmbedHtmlForGoogleMap.$dirty) return errors;
      !this.$v.EmbedHtmlForGoogleMap.maxLength && errors.push("2000文字以内で入力してください");
      //!this.$v.EmbedHtmlForGoogleMap.required && errors.push("入力必須項目です");
      return errors;
    },
    storeLogoFilenameErrors() {
      const errors = [];
      if (!this.$v.StoreLogoFileUrl.$dirty) return errors;
      //!this.$v.StoreLogoFileUrl.required && errors.push("入力必須項目です");
      return errors;
    },
    storeGroupErrors() {
      const errors = [];
      if (!this.$v.StoreGroup.$dirty) return errors;
      !this.$v.StoreGroup.maxLength && errors.push("45文字以内で入力してください");
      return errors;
    },
  },
  async mounted() {
    if (this.$route.params.storeId) {
      this.getLoading = true;
      this.isUpdate = true;
      await axios.get("/stores/" + this.$route.params.storeId).then(async res => {
        const s3BaseUrl = process.env.NODE_ENV === "production" ?
            `https://${res.data.Company.Domain}.tt-recruit.com/img/` :
            `${process.env.VUE_APP_PREVIEW_ENDPOINT}/${res.data.Company.Domain}/img/`;
        this.CompanyDomain = res.data.Company.Domain;
        this.StoreId = res.data.StoreId;
        this.StoreName = res.data.StoreName;
        this.NumberOfSeats = res.data.NumberOfSeats;
        this.TypeOfBusiness = res.data.TypeOfBusiness;
        this.Pref = res.data.Pref;
        this.WorkLocation = res.data.WorkLocation;
        this.NearestStation = res.data.NearestStation;
        this.EmbedHtmlForGoogleMap = res.data.EmbedHtmlForGoogleMap;
        this.StoreLogoFilename = res.data.StoreLogoFilename;
        this.StoreLogoFileUrl = s3BaseUrl + res.data.StoreLogoFilename;
        this.StoreGroup = res.data.StoreGroup;
        this.UpdatedByName = res.data.UpdatedByName;
        this.UpdatedAt = dayjs(res.data.UpdatedAt).format("YYYY-MM-DD HH:mm:ss");
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.getLoading = false;
      });
    }
     else{
      const res = await axios.get("/companies/" + this.$route.params.companyId);
      this.CompanyDomain = res.data.Domain;
    }
  }
}
</script>

<style scoped>
.custom-file-input >>> .v-input__append-inner,
.custom-file-input >>> .v-input__prepend-outer {
  margin: 10px 5px;
}

.image-styles {
  max-width: 100%;
  height: 300px;
  width: 100%;
  object-fit: cover;
}
</style>
