export default {
    //記事ヘッダー
    csvHeaderArticle: [
        "記事ID",
        "表示タイトル",
        "ステータス",
        "トップ画像名",
        "動画ID/動画ファイル名",
        "雇用形態",
        "職種",
        "職種 検索用",
        "勤務エリア 検索用",
        "業態 検索用",
        "仕事内容",
        "給与",
        "給与詳細",
        "福利厚生",
        "勤務時間",
        "勤務詳細",
        "応募資格",
        "歓迎要件",
        "タググループID",
        "店舗ID",
        "どんな会社でどんな仕事を タイトル",
        "どんな会社でどんな仕事を 並び順",
        "代表挨拶 ヘッダータイトル",
        "代表挨拶 並び順",
        "代表挨拶 画像名",
        "代表挨拶 タイトル",
        "代表挨拶 内容",
        "インタビュー タイトル",
        "インタビュー 並び順",
        "人気の秘訣 タイトル",
        "人気の秘訣 並び順",
        "連絡先ID",
        "電話で応募ボタン非表示",
        "コメント",
        "記事種別",
        "最終更新者",
        "最終更新日",
    ],
}
