<template>
  <div>
    <header-top-dashboard primary>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <Overlay :loading="getLoading" />
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                <span v-show="!isUpdate">タググループ作成</span>
                <span v-show="isUpdate">タググループ更新</span>
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="12" md="8">
                  <p class="font-size-root text-body font-weight-thin" v-show="!isUpdate">
                    新しいタググループを登録します。<br>
                  </p>
                  <p class="font-size-root text-body font-weight-thin" v-show="isUpdate">
                    タググループを更新します。<br>
                  </p>
                </v-col>
              </v-row>
              <div class="border-bottom my-6"></div>

              <v-form class="mt-13">
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >タググループID</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="TagGroupId"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>タググループ名</label
                    >
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="例）正社員求人タグ"
                        v-model="TagGroupName"
                        :error-messages="tagGroupNameErrors"
                        :counter="255"
                        required
                        @input="$v.TagGroupName.$touch()"
                        @blur="$v.TagGroupName.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mb-6">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>タグ</label
                    >
                    <span v-for="tag in TagSelectors">
                      <v-checkbox dense v-model="SelectedTags" :value="tag.TagId" :label="tag.TagName" hide-details></v-checkbox>
                    </span>
                  </v-col>
                </v-row>
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >最終更新者</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="UpdatedByName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >最終更新日</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="UpdatedAt"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <div class="text-right">
                  <v-btn v-show="!isUpdate"
                         @click="submitCreate"
                         elevation="0"
                         :ripple="false"
                         height="43"
                         class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                         color="green"
                         :loading="loading"
                         large
                  >作成</v-btn
                  >
                  <v-btn v-show="isUpdate"
                         @click="submitUpdate"
                         elevation="0"
                         :ripple="false"
                         height="43"
                         class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                         color="green"
                         :loading="loading"
                         large
                  >更新</v-btn
                  >
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength, numeric } from "vuelidate/lib/validators";
import dayjs from "dayjs";
import HeaderTopDashboard from "../../../components/HeaderTopDashboard";
import Overlay from '../../../components/Overlay.vue';

export default {
  name: "TagGroupDetail",
  components: {
    HeaderTopDashboard,
    Overlay
  },
  data() {
    return {
      TagSelectors: [],
      SelectedTags: [],
      TagGroupId: "",
      TagGroupName: "",
      TagGroupTags: [{
        TagGroupTagId: null,
        TagGroupId: null,
        TagId: null,
      }],
      UpdatedByName: "",
      UpdatedAt: "",
      loading: false,
      getLoading: false,
      isUpdate: false,
    }
  },
  mixins: [validationMixin],
  methods: {
    submitCreate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios.post("/tag-groups", {
        TagGroupName: this.TagGroupName,
        CompanyId: Number(this.$route.params.companyId),
        TagGroupTags: this.SelectedTags.map(v => {
          return {TagId: v}
        })
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({ name: "TagGroups" });
      }).catch(e => {
        const res = e.response;
        if (res && res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    submitUpdate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios.put("/tag-groups/" + this.TagGroupId, {
        TagGroupId: this.TagGroupId,
        TagGroupName: this.TagGroupName,
        CompanyId: Number(this.$route.params.companyId),
        TagGroupTags: this.SelectedTags.map(v => {
          return {TagId: v}
        })
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({ name: "TagGroups" });
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    ...mapActions("snackbar", ["openSnackbarSuccess", "openSnackbarApiError", "openSnackbarValidationError"]),
  },
  validations: {
    TagGroupName: { required, maxLength: maxLength(255) },
  },
  computed: {
    tagGroupNameErrors() {
      const errors = [];
      if (!this.$v.TagGroupName.$dirty) return errors;
      !this.$v.TagGroupName.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.TagGroupName.required && errors.push("入力必須項目です");
      return errors;
    },
  },
  mounted() {
    if (this.$route.params.tagGroupId) {
      this.getLoading = true;
      this.isUpdate = true;
      axios.get("/tag-groups/" + this.$route.params.tagGroupId).then(res => {
        this.TagGroupId = res.data.TagGroupId;
        this.TagGroupName = res.data.TagGroupName;
        this.SelectedTags = res.data.TagGroupTags.map(v => v.TagId);
        this.UpdatedByName = res.data.UpdatedByName;
        this.UpdatedAt = dayjs(res.data.UpdatedAt).format("YYYY-MM-DD HH:mm:ss");
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.getLoading = false;
      });
    }

    axios.get("/tags").then(res => {
      this.TagSelectors = res.data;
    }).catch(e => {
      const res = e.response;
      if (res.status === 400) {
        this.openSnackbarValidationError(res.data)
      }
    });
  }
}
</script>

<style scoped>

</style>