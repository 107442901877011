<template>
  <div>
    <header-top-dashboard primary is-admin>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <Overlay :loading="getLoading" />
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                <span v-show="!isUpdate">タグ作成</span>
                <span v-show="isUpdate">タグ更新</span>
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="12" md="8">
                  <p class="font-size-root text-body font-weight-thin" v-show="!isUpdate">
                    新しいタグを登録します。<br>
                    タグのカラーを追加したい場合は、システム担当者にご連絡ください。<br>
                  </p>
                  <p class="font-size-root text-body font-weight-thin" v-show="isUpdate">
                    タグを更新します。<br>
                    タグのカラーを追加したい場合は、、システム担当者にご連絡ください。<br>
                  </p>
                </v-col>
              </v-row>
              <div class="border-bottom my-6"></div>

              <v-form class="mt-13">
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >タグID</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="TagId"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>タグ名</label
                    >
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="例）正社員"
                        v-model="TagName"
                        :error-messages="tagNameErrors"
                        :counter="255"
                        required
                        @input="$v.TagName.$touch()"
                        @blur="$v.TagName.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>タグカラー</label
                    >
                    <v-select
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        v-model="TagColor"
                        :items="tagColorItem"
                        item-text="TagColorName"
                        item-value="TagColorClass"
                        @input="$v.TagColor.$touch()"
                        @blur="$v.TagColor.$touch()"
                        :error-messages="tagColorErrors"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >最終更新者</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="UpdatedByName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >最終更新日</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="UpdatedAt"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <div class="text-right">
                  <v-btn v-show="!isUpdate"
                      @click="submitCreate"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                      color="green"
                      :loading="loading"
                      large
                  >作成</v-btn
                  >
                  <v-btn v-show="isUpdate"
                         @click="submitUpdate"
                         elevation="0"
                         :ripple="false"
                         height="43"
                         class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                         color="green"
                         :loading="loading"
                         large
                  >更新</v-btn
                  >
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength, url } from "vuelidate/lib/validators";
import Overlay from '../../../components/Overlay.vue';
import HeaderTopDashboard from "../../../components/HeaderTopDashboard";
import dayjs from "dayjs";

export default {
  name: "TagDetail",
  components: {
    HeaderTopDashboard,
    Overlay
  },
  data() {
    return {
      tagColorItem: [
        {TagColorClass: "bac-c-main", TagColorName: "メインカラー"},
        {TagColorClass: "bac-c-sub", TagColorName: "青"},
        {TagColorClass: "bac-c-sub2", TagColorName: "緑"},
      ],

      TagId: "",
      TagName: "",
      TagColor: "",
      UpdatedByName: "",
      UpdatedAt: "",
      loading: false,
      getLoading: false,
      isUpdate: false,
    }
  },
  mixins: [validationMixin],
  methods: {
    submitCreate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios.post("/tags", {
        TagName: this.TagName,
        TagColor: this.TagColor,
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({ name: "Tags" });
      }).catch(e => {
        const res = e.response;
        if (res && res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    submitUpdate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios.put("/tags/" + this.TagId, {
        TagId: this.TagId,
        TagName: this.TagName,
        TagColor: this.TagColor,
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({ name: "Tags" });
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    ...mapActions("snackbar", ["openSnackbarSuccess", "openSnackbarApiError", "openSnackbarValidationError"]),
  },
  validations: {
    TagName: { required, maxLength: maxLength(255) },
    TagColor: { required, maxLength: maxLength(255) },
  },
  computed: {
    tagNameErrors() {
      const errors = [];
      if (!this.$v.TagName.$dirty) return errors;
      !this.$v.TagName.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.TagName.required && errors.push("入力必須項目です");
      return errors;
    },
    tagColorErrors() {
      const errors = [];
      if (!this.$v.TagColor.$dirty) return errors;
      !this.$v.TagColor.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.TagColor.required && errors.push("入力必須項目です");
      return errors;
    },
  },
  mounted() {
    if (this.$route.params.tagId) {
      this.getLoading = true;
      this.isUpdate = true;
      axios.get("/tags/" + this.$route.params.tagId).then(res => {
        this.TagId = res.data.TagId;
        this.TagName = res.data.TagName;
        this.TagColor = res.data.TagColor;
        this.UpdatedByName = res.data.UpdatedByName;
        this.UpdatedAt = dayjs(res.data.UpdatedAt).format("YYYY-MM-DD HH:mm:ss");
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        } else {
          console.log(e.response)
          this.openSnackbarApiError();
        }
      }).finally(() => {
        this.getLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>