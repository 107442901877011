<template>
  <v-card class="card-shadow">
    <div class="card-header-padding card-border-bottom">
      <p class="font-weight-600 text-h4 text-typo pb-0 mb-0">通知 - 最新5件</p>
    </div>
    <v-card-text class="card-padding py-3">
      <v-list class="py-0">
        <v-list-item
          v-for="(chat, index) in checklist"
          :key="chat.title"
          class="mt-0 px-0 py-1 align-stretch"
          :class="{ 'border-bottom': index != checklist.length - 1 }"
        >
          <div
            class="checklist-line-width my-2 me-2 rounded"
            :class="`bg-${chat.color}`"
          ></div>
          <v-list-item-content>
            <v-list-item-title
              v-text="chat.title"
              class="text-typo text-h5 font-weight-600 mb-2"
              :class="{
                'text-decoration-line-through': checkList(index),
              }"
            ></v-list-item-title>
            <span
              class="text-body"
              :class="{
                'text-decoration-line-through': checkList(index),
              }"
            >
              {{ chat.status }}
            </span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "NotificationList",
  data() {
    return {
      checkboxModel: [],
      checklist: [
        {
          title: "XX店に新しい応募がありました",
          status: "10:30 am",
          color: "success",
        },
        {
          title: "YY店に新しい応募がありました",
          status: "11:30 am",
          color: "success",
        },
        {
          title: "QQ店が新しく作成されました",
          status: "10:30 am",
          color: "success",
        },
        {
          title: "ZZさんを採用しました",
          status: "10:30 am",
          color: "warning",
        },
        {
          title: "NNさんを採用しました",
          status: "10:30 am",
          color: "warning",
        },
      ],
    };
  },
  methods: {
    checkList(index) {
      if (this.checkboxModel.includes(index)) {
        return true;
      } else {
        return;
      }
    },
  },
};
</script>
