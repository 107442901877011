<template>
  <v-navigation-drawer
      width="250"
      fixed
      app
      floating
      :expand-on-hover="mini"
      :value="drawer"
  >
    <v-list-item class="pa-0">
      <v-list-item-content class="pa-0">
        <v-list-item-title class="title d-flex align-center mb-0">
          <div class="v-navigation-drawer-brand pa-5 d-flex align-center" @click="moveTop">
            <img src="@/assets/tprlogo.png" class="w-90" />
          </div>

          <div
              class="drawer-toggler pa-5 cursor-pointer"
              :class="{ active: togglerActive }"
              @click="minifyDrawer"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line bg-default"></i>
              <i class="drawer-toggler-line bg-default"></i>
              <i class="drawer-toggler-line bg-default"></i>
            </div>
          </div>
        </v-list-item-title>
        <span
            class="text-caption font-weight-600 mx-5 mb-4 white-space-nowrap"
        >
          システム管理
        </span>
      </v-list-item-content>
    </v-list-item>

    <v-list nav dense>
      <h5
          class="text-uppercase text-caption ls-0 font-weight-600 p-0 text-muted mt-6 mx-3 mb-4 d-none-mini white-space-nowrap"
      >
        管理
      </h5>

      <v-list-item-group>
        <v-list-item
            v-for="(item, i) in itemsSettings"
            :key="i"
            link
            :to="item.link"
            class="pb-1 no-default-hover"
            :ripple="false"
            active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon class="ml-1 mr-1 tag">{{item.icon}}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<style scoped>
.tag {
  width: 25px;
}
</style>
<script>
export default {
  name: "adminDrawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    itemsSettings: [
      {
        icon: "fa-building",
        link: "/admin/companies",
        title: "企業管理",
      },
      {
        icon: "fa-users",
        link: "/admin/users",
        title: "ユーザー管理",
      },
      {
        icon: "fa-tags",
        link: "/admin/tags",
        title: "タグ管理",
      }
    ],
  }),
  methods: {
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
    moveTop() {
      location.href = "/";
    }
  },
};
</script>
