<template>
  <v-dialog width="538" v-model="deleteDialog" @click:outside="closeDelete">
    <template>
      <v-card class="card-shadow bg-gradient-danger">
        <div
          class="card-header-padding d-flex align-center justify-space-between pb-10"
        >
          <v-card-title class="pa-0 text-h3 font-weight-600 text-white"
            >注意</v-card-title
          >
          <v-btn
            icon
            @click="closeDelete"
            elevation="0"
            :ripple="false"
            height="38"
            min-width="38"
            width="38"
            class="font-weight-600 text-capitalize btn-ls btn-hover rounded-sm"
            color="rgba(255,255,255,.6)"
          >
            <v-icon size="13">fas fa-times</v-icon>
          </v-btn>
        </div>
        <v-card-text class="card-padding pt-6 text-center">
          <v-icon size="48" class="text-white">ni-bell-55</v-icon>
          <h4 class="heading mt-6 text-white font-weight-600 text-uppercase">
            店舗ID {{ deleteArticleId }}
            {{ deleteArticleName }} を削除しようとしています
          </h4>
          <p class="text-white font-size-root font-weight-thin mt-3">
            記事に紐付く情報も全て削除されます。<br />
            削除された記事は元に戻せません。<br />
            本当に削除してよろしいですか？
          </p>
        </v-card-text>
        <div class="card-padding d-flex align-center justify-space-between">
          <v-btn
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize btn-secondary py-3 px-6 rounded-sm"
            color="#fff"
            @click="$emit('deleteArticle')"
            :loading="deleteLoading"
            >はい、削除します</v-btn
          >
          <v-btn
            @click="closeDelete"
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize text-white py-3 px-6 rounded-sm"
            color="transparent"
            >閉じる</v-btn
          >
        </div>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
export default {
  name: "deleteModal",
  props: {
    deleteDialog: {
      type: String,
      default: "",
    },
    deleteLoading: {
      type: Boolean,
      default: false,
    },
    deleteArticleId: {
      type: String | Number,
      default: false,
    },
    deleteArticleName: {
      type: String,
      default: false,
    },
  },
  emits: ["deleteArticle", "closeDeleteDialog"],

  methods: {
    closeDelete() {
      this.$emit("closeDeleteDialog");
    },
  },
};
</script>

<style scoped></style>
