<template>
  <div>
    <header-top-dashboard primary>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <Overlay :loading="getLoading" />
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                <span v-show="!isUpdate">候補者作成</span>
                <span v-show="isUpdate">候補者更新</span>
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="12" md="8">
                  <p class="font-size-root text-body font-weight-thin" v-show="!isUpdate">
                    新しい候補者を登録します。<br>
                  </p>
                  <p class="font-size-root text-body font-weight-thin" v-show="isUpdate">
                    候補者を更新します。<br>
                  </p>
                </v-col>
              </v-row>
              <div class="border-bottom my-6"></div>

              <v-form class="mt-13">
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >
                      候補者ID
                    </label>
                    <v-text-field
                      outlined
                      class="font-size-input"
                      v-model="CandidateId"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                    <span class="red--text">* </span>ステータス</label>
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="Status"
                      :items="statusItem"
                      item-text="text"
                      item-value="value"
                      persistent-hint
                      :error-messages="statusErrors"
                      @input="$v.Status.$touch()"
                      @blur="$v.Status.$touch()"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >雇用形態</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder='例）正社員'
                      v-model="EmploymentStatus"
                      :error-messages="employmentStatusErrors"
                      required
                      :counter="50"
                      @input="$v.EmploymentStatus.$touch()"
                      @blur="$v.EmploymentStatus.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >職種</label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder='例）調理スタッフ'
                      v-model="Occupation"
                      :error-messages="occupationErrors"
                      required
                      :counter="50"
                      @input="$v.Occupation.$touch()"
                      @blur="$v.Occupation.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                    <span class="red--text">* </span>
                      店舗
                    </label>
                    <StoreSelector
                      ref="store"
                      v-model="Store"
                      :error-messages="storeErrors"
                      @change="$v.Store.$touch()"
                      @blur="$v.Store.$touch()"
                    >
                    </StoreSelector>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      氏名
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder='例）姓名 漢字'
                      v-model="Name"
                      :error-messages="nameErrors"
                      required
                      :counter="255"
                      @input="$v.Name.$touch()"
                      @blur="$v.Name.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      かな
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder='例）せいめい かな'
                      v-model="Kana"
                      :error-messages="kanaErrors"
                      required
                      :counter="255"
                      @input="$v.Kana.$touch()"
                      @blur="$v.Kana.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      メールアドレス
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder='例）triple.page@333-inc.com'
                      v-model="Email"
                      :error-messages="emailErrors"
                      required
                      :counter="255"
                      @input="$v.Email.$touch()"
                      @blur="$v.Email.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>電話番号</label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder='例）08012345678'
                      v-model="Tel"
                      :error-messages="telErrors"
                      required
                      :counter="12"
                      @input="$v.Tel.$touch()"
                      @blur="$v.Tel.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>生年月日</label
                    >
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="Birth"
                          prepend-icon="mdi-calendar"
                          readonly
                          :error-messages="birthErrors"
                          @input="$v.Birth.$touch()"
                          @blur="$v.Birth.$touch()"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        locale="jp-ja"
                        v-model="Birth"
                        color="#46f3c4"
                        :day-format="date => new Date(date).getDate()"
                        :max="new Date().toISOString().substr(0, 10)"
                        :picker-date="pickerDate"
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      性別
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="Gender"
                      :error-messages="genderErrors"
                      required
                      :counter="255"
                      @input="$v.Tel.$touch()"
                      @blur="$v.Tel.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      都道府県
                    </label>
                    <PrefSelector
                      ref="pref"
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="Pref"
                      :error-messages="prefErrors"
                      @input="$v.Tel.$touch()"
                      @blur="$v.Tel.$touch()"
                    >
                    </PrefSelector>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      住所
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder='例）川崎市中原区新丸子町755-4'
                      v-model="City"
                      :error-messages="cityErrors"
                      required
                      :counter="255"
                      @input="$v.City.$touch()"
                      @blur="$v.City.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      候補者備考
                    </label>
                    <v-textarea
                      outlined
                      readonly
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="Note"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      紹介
                    </label>
                    <v-checkbox
                      outlined
                      class="font-size-input text-light-input placeholder-light"
                      v-model="Referral"
                      label="有"
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      紹介者の名前
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder='例）紹介者氏名'
                      v-model="Referrer"
                      :error-messages="referrerErrors"
                      required
                      :counter="255"
                      @input="$v.Referrer.$touch()"
                      @blur="$v.Referrer.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      <span class="red--text">* </span>
                      応募方法
                    </label>
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="ReceivingMethod"
                      :items="receivingMethodItem"
                      item-text="text"
                      item-value="value"
                      persistent-hint
                      :error-messages="receivingMethodErrors"
                      @input="$v.ReceivingMethod.$touch()"
                      @blur="$v.ReceivingMethod.$touch()"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >応募URL</label
                    >
                    {{ ApplyUrl }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      採用側メモ
                    </label>
                    <v-textarea
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder='例）採用側任意のメモ'
                      v-model="Memo"
                      required
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      応募日時
                    </label>
                    <v-text-field
                      outlined
                      readonly
                      class="font-size-input"
                      v-model="ApplyAt"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      最終更新者
                    </label>
                    <v-text-field
                      outlined
                      class="font-size-input"
                      v-model="UpdatedByName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      最終更新日
                    </label>
                    <v-text-field
                      outlined
                      class="font-size-input"
                      v-model="UpdatedAt"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <div class="text-right">
                  <v-btn v-show="!isUpdate"
                    @click="submitCreate"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                    color="green"
                    :loading="loading"
                    large
                  >
                    作成
                  </v-btn>
                  <v-btn v-show="isUpdate"
                    @click="submitUpdate"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                    color="green"
                    :loading="loading"
                    large
                  >
                    更新
                  </v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import {required, maxLength, email, } from "vuelidate/lib/validators";
import HeaderTopDashboard from "../../../components/HeaderTopDashboard";
import StoreSelector from "../../../components/Selectors/StoreSelector";
import PrefSelector from "../../../components/Selectors/PrefSelector";
import Overlay from '../../../components/Overlay.vue';
import dayjs from "dayjs";
import CANDIDATE_STATUS from "@/const/candidateItem.js";

const REFERRAL_FALSE = 1;
const REFERRAL_TRUE = 2;

export default {
  name: "CandidateDetail",
  components: {
    PrefSelector,
    StoreSelector,
    HeaderTopDashboard,
    Overlay
  },
  data() {
    return {
      CandidateId: "",
      ArticleId: null,
      Article: null,
      Status: null,
      statusItem: CANDIDATE_STATUS.status,
      receivingMethodItem: [
        { value: 1, text: "WEB"},
        { value: 2, text: "電話"},
        { value: 3, text: "メール"},
        { value: 9, text: "その他"},
      ],
      EmploymentStatus: "",
      Occupation: "",
      Store: null,
      Name: "",
      Kana: "",
      Email: "",
      Tel: "",
      Birth: null,
      Gender: "",
      Pref: "",
      City: "",
      Note: "",
      Referral: null,
      Referrer: "",
      ApplyUrl: "",
      Memo: "",
      Age: null,
      ReceivingMethod: null,
      ApplyAt: null,
      UpdatedByName: "",
      UpdatedAt: "",
      loading: false,
      getLoading: false,
      isUpdate: false,
      menu: false,
      pickerDate: '1995-1-1',
    }
  },
  mixins: [validationMixin],
  watch: {
    menu (val) {
      val && setTimeout(() => (
        this.$refs.picker.activePicker = 'YEAR',
        // 選び始めたら初期化
        this.pickerDate = null
      ))
    },
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date);
      this.pickerDate = date;
    },
    submitCreate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios.post("/candidates", {
        Status: this.Status,
        EmploymentStatus: this.EmploymentStatus,
        Occupation: this.Occupation,
        StoreId: this.Store?.StoreId,
        Name: this.Name,
        Kana: this.Kana,
        Email: this.Email,
        Tel: this.Tel,
        Birth: this.Birth + "T00:00:00Z",
        Gender: this.Gender,
        Pref: this.Pref,
        City: this.City,
        Note: this.Note,
        Referral: this.Referral ? REFERRAL_TRUE : REFERRAL_FALSE,
        Referrer: this.Referrer,
        ApplyUrl: this.ApplyUrl,
        Memo: this.Memo,
        ReceivingMethod: this.ReceivingMethod,
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({ name: "Candidates" });
      }).catch(e => {
        const res = e.response;
        if (res && res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    submitUpdate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios.put("/candidates/" + this.CandidateId, {
        CandidateId: this.CandidateId,
        ArticleId: this.ArticleId,
        Status: this.Status,
        EmploymentStatus: this.EmploymentStatus,
        Occupation: this.Occupation,
        StoreId: this.Store?.StoreId,
        Name: this.Name,
        Kana: this.Kana,
        Email: this.Email,
        Tel: this.Tel,
        Birth: this.Birth + "T00:00:00Z",
        Gender: this.Gender,
        Pref: this.Pref,
        City: this.City,
        Note: this.Note,
        Referral: this.Referral ? REFERRAL_TRUE : REFERRAL_FALSE,
        Referrer: this.Referrer,
        ApplyUrl: this.ApplyUrl,
        Memo: this.Memo,
        Age: this.Age,
        ReceivingMethod: this.ReceivingMethod,
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({ name: "Candidates" });
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    ...mapActions("snackbar", ["openSnackbarSuccess", "openSnackbarApiError", "openSnackbarValidationError"]),
  },
  validations: {
    Status: { required },
    EmploymentStatus: { maxLength: maxLength(50) },
    Occupation: { maxLength: maxLength(50) },
    Name: { maxLength: maxLength(255) },
    Kana: { maxLength: maxLength(255) },
    Tel: { required, maxLength: maxLength(255) },
    Birth: { required },
    Gender: {maxLength: maxLength(255)},
    Email: { email },
    Store: { required },
    Pref: { maxLength: maxLength(4) },
    City: { maxLength: maxLength(255) },
    Referrer: { maxLength: maxLength(255) },
    ReceivingMethod: { required },

  },
  computed: {
    statusErrors() {
      const errors = [];
      if (!this.$v.Status.$dirty) return errors;
      !this.$v.Status.required && errors.push("入力必須項目です");
      return errors;
    },
    employmentStatusErrors() {
      const errors = [];
      if (!this.$v.EmploymentStatus.$dirty) return errors;
      !this.$v.EmploymentStatus.maxLength && errors.push("50文字以内で入力してください");
      return errors;
    },
    occupationErrors() {
      const errors = [];
      if (!this.$v.Occupation.$dirty) return errors;
      !this.$v.Occupation.maxLength && errors.push("50文字以内で入力してください");
      return errors;
    },
    storeErrors() {
      const errors = [];
      if (!this.$v.Store.$dirty) return errors;
      !this.$v.Store.required && errors.push("入力必須項目です");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.Name.$dirty) return errors;
      !this.$v.Name.maxLength && errors.push("255文字以内で入力してください");
      return errors;
    },
    kanaErrors() {
      const errors = [];
      if (!this.$v.Kana.$dirty) return errors;
      !this.$v.Kana.maxLength && errors.push("255文字以内で入力してください");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.Email.$dirty) return errors;
      !this.$v.Email.email && errors.push("メールアドレスの形式で入力してください");
      return errors;
    },
    telErrors() {
      const errors = [];
      if (!this.$v.Tel.$dirty) return errors;
      !this.$v.Tel.maxLength && errors.push("45文字以内で入力してください");
      !this.$v.Tel.required && errors.push("入力必須項目です");
      return errors;
    },
    prefErrors() {
      const errors = [];
      if (!this.$v.Pref.$dirty) return errors;
      !this.$v.Pref.maxLength && errors.push("4文字以内で入力してください");
      return errors;
    },
    birthErrors() {
      const errors = [];
      if (!this.$v.Birth.$dirty) return errors;
      !this.$v.Birth.required && errors.push("入力必須項目です");
      return errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.Gender.$dirty) return errors;
      !this.$v.Gender.maxLength && errors.push("255文字以内で入力してください");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.City.$dirty) return errors;
      !this.$v.City.maxLength && errors.push("255文字以内で入力してください");
      return errors;
    },
    referrerErrors() {
      const errors = [];
      if (!this.$v.Referrer.$dirty) return errors;
      !this.$v.Referrer.maxLength && errors.push("255文字以内で入力してください");
      return errors;
    },
    receivingMethodErrors() {
      const errors = [];
      if (!this.$v.ReceivingMethod.$dirty) return errors;
      !this.$v.ReceivingMethod.required && errors.push("入力必須項目です");
      return errors;
    },
  },
  async mounted() {
    if (this.$route.params.candidateId) {
      this.getLoading = true;
      this.isUpdate = true;
      try {
        const res = await axios.get("/candidates/" + this.$route.params.candidateId);
        this.CandidateId = res.data.CandidateId;
        this.Status = res.data.Status;
        this.EmploymentStatus = res.data.EmploymentStatus;
        this.Occupation = res.data.Occupation;
        const promiseStore = this.$refs.store.setInitialValue(res.data.StoreId);
        await Promise.all([promiseStore]);
        this.Name = res.data.Name;
        this.Kana = res.data.Kana;
        this.Email = res.data.Email;
        this.Tel = res.data.Tel;
        if (dayjs(res.data.Birth).isValid()) {
          this.Birth = dayjs(res.data.Birth).format("YYYY-MM-DD");
        }
        this.Gender = res.data.Gender;
        this.Pref = res.data.Pref;
        this.$refs.pref.setInitialValue(this.Pref);
        this.City = res.data.City;
        this.Note = res.data.Note;
        this.Referral = res.data.Referral === REFERRAL_TRUE ? 1 : 0;
        this.Referrer = res.data.Referrer;
        this.ApplyUrl = res.data.ApplyUrl;
        this.Memo = res.data.Memo;
        this.ReceivingMethod = res.data.ReceivingMethod;
        this.Age = res.data.Age;
        this.ApplyAt = dayjs(res.data.ApplyAt).format("YYYY-MM-DD　HH:mm:ss");
        this.UpdatedByName = res.data.UpdatedByName;
        this.UpdatedAt = dayjs(res.data.UpdatedAt).format("YYYY-MM-DD HH:mm:ss");
      } catch(e) {
        console.log(e);
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      } finally {
        this.getLoading = false;
      }
    }
  }
}
</script>

<style scoped>

</style>