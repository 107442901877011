<template>
  <v-dialog width="538" v-model="emailDialog" @click:outside="closeEmail">
    <template>
      <v-card class="card-shadow">
        <div
          class="card-header-padding d-flex align-center justify-space-between pb-10"
        >
          <v-card-title class="pa-0 text-h3 font-weight-600">
            メール一括送信
          </v-card-title>
          <v-btn
            icon
            @click="closeEmail"
            elevation="0"
            :ripple="false"
            height="38"
            min-width="38"
            width="38"
            class="font-weight-600 text-capitalize btn-ls rounded-sm"
            color="rgba(0,0,0,.6)"
          >
            <v-icon size="13">fas fa-times</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <div>
            <label class="label-color font-weight-600"
              ><span class="red--text">* </span> 件名
            </label>
            <v-text-field
              outlined
              class="input-style font-size-input text-light-input placeholder-light"
              placeholder="件名"
              v-model="subject"
              :error-messages="subjectErrors"
              required
              @input="$v.subject.$touch()"
              @blur="$v.subject.$touch()"
            >
            </v-text-field>
          </div>
          <div>
            <label class="label-color font-weight-600"
              ><span class="red--text">* </span> メッセージ
            </label>
            <v-textarea
              outlined
              class="input-style font-size-input text-light-input placeholder-light"
              placeholder="メッセージ"
              v-model="message"
              :error-messages="messageErrors"
              @input="$v.message.$touch()"
              @blur="$v.message.$touch()"
              required
            >
            </v-textarea>
          </div>
        </v-card-text>
        <div class="card-padding d-flex align-center justify-space-between">
          <v-btn
            @click="closeEmail"
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
          >
            閉じる
          </v-btn>
          <v-btn
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize btn-secondary py-3 px-6 rounded-sm"
            color="#fff"
            @click="sendBulkEmail"
            :loading="emailLoading"
          >
            送信する
          </v-btn>
        </div>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  name: "EmailModal",
  props: {
    emailDialog: {
      type: String,
      default: "",
    },
    selectedEmail: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["closeEmailDialog"],
  data() {
    return {
      emailLoading: false,
      subject: "",
      message: "",
    };
  },
  mixins: [validationMixin],
  validations: {
    subject: { required },
    message: { required },
  },
  computed: {
    subjectErrors() {
      const errors = [];
      if (!this.$v.subject.$dirty) return errors;
      !this.$v.subject.required && errors.push("入力必須項目です");
      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.required && errors.push("入力必須項目です");
      return errors;
    },
  },

  methods: {
    closeEmail() {
      this.$emit("closeEmailDialog");
    },
    sendBulkEmail() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.emailLoading = true;
      axios
        .post("/candidates/send-mails", {
          Emails: this.selectedEmail.filter((isNumber) => isNaN(isNumber)),
          Subject: this.subject,
          Message: this.message,
        })
        .then(() => {
          this.openSnackbarSuccess();
          this.subject = "";
          this.message = "";
        })
        .catch((e) => {
          const res = e.response;
          if (res && res.status === 400) {
            this.openSnackbarValidationError(res.data.message);
          }
        })
        .finally(() => {
          this.emailLoading = false;
          this.$emit("closeEmailDialog");
        });
    },
    ...mapActions("snackbar", [
      "openSnackbarSuccess",
      "openSnackbarApiError",
      "openSnackbarValidationError",
    ]),
  },
};
</script>

<style scoped></style>
