<template>
  <div>
    <header-top-dashboard primary is-admin>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <Overlay :loading="getLoading" />
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                <span v-show="!isUpdate">企業作成</span>
                <span v-show="isUpdate">企業更新</span>
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="12" md="8">
                  <p class="font-size-root text-body font-weight-thin" v-show="!isUpdate">
                    新しい企業を登録します。ドメインはシステム全体で一つしか登録できません。<br>
                    使いたいドメインが既に登録されている場合は、システム担当者にご連絡ください。<br>
                  </p>
                  <p class="font-size-root text-body font-weight-thin" v-show="isUpdate">
                    企業を更新します。ドメインはシステム全体で一つしか登録できません。<br>
                    使いたいドメインが既に登録されている場合は、システム担当者にご連絡ください。<br>
                  </p>
                </v-col>
              </v-row>
              <div class="border-bottom my-6"></div>

              <v-form class="mt-13">
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      企業ID
                    </label>
                    <v-text-field
                      outlined
                      class="font-size-input"
                      v-model="CompanyId"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>
                      企業名
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="例）株式会社トリプルスリー"
                      v-model="CompanyName"
                      :error-messages="companyNameErrors"
                      :counter="255"
                      required
                      @input="$v.CompanyName.$touch()"
                      @blur="$v.CompanyName.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>
                      ドメイン
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="例）333-inc"
                      v-model="Domain"
                      :error-messages="domainErrors"
                      :counter="50"
                      required
                      @input="$v.Domain.$touch()"
                      @blur="$v.Domain.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>
                      会社トップタイトル
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="例）トリプルスリー"
                      v-model="CompanyTopTitle"
                      :error-messages="companyTopTitleErrors"
                      :counter="255"
                      required
                      @input="$v.CompanyTopTitle.$touch()"
                      @blur="$v.CompanyTopTitle.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      ウェブサイトURL
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="例）https://333-inc.com/"
                      v-model="CompanyWebsiteUrl"
                      :error-messages="companyWebsiteUrlErrors"
                      :counter="1000"
                      required
                      @input="$v.CompanyWebsiteUrl.$touch()"
                      @blur="$v.CompanyWebsiteUrl.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                     <div>
                      <label
                          class="label-color font-weight-600 d-block"
                      ><span class="red--text">* </span>ロゴファイル名</label>
                      <v-input
                          :error-messages="companyLogoFilenameErrors"
                      >
                        <v-file-input
                            type="file"
                            outlined
                            class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                            :placeholder="isUpdate && CompanyLogoFilename ? '' :'例）image'"
                            accept="image/*"
                            hide-details
                            required
                            @change="(e) => uploadFile(e)"
                            @input="$v.CompanyLogoFileUrl.$touch()"
                            @blur="$v.CompanyLogoFileUrl.$touch()"
                        >
                          <template v-if="isUpdate && CompanyLogoFilename && CompanyLogoFile.entries().next().done"
                                    #prepend-inner>
                            {{ CompanyLogoFilename }}
                          </template>
                        </v-file-input>
                      </v-input>
                    </div>
                    <div class="d-flex justify-center mb-2">
                      <img :src="CompanyLogoFileUrl" alt="Uploaded Image" v-if="CompanyLogoFileUrl" class="image-styles"/>
                    </div>
                  </v-col>
                  
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      企業理念
                    </label>
                    <v-textarea
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="CorporatePhilosophy"
                      :error-messages="corporatePhilosophyErrors"
                      :counter="1000"
                      required
                      @input="$v.CorporatePhilosophy.$touch()"
                      @blur="$v.CorporatePhilosophy.$touch()"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      将来ビジョン・目標
                    </label>
                    <v-textarea
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="Vision"
                      :error-messages="visionErrors"
                      :counter="10000"
                      required
                      @input="$v.Vision.$touch()"
                      @blur="$v.Vision.$touch()"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >本社所在地</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="例）神奈川県川崎市武蔵小杉"
                      v-model="HeadOfficeLocation"
                      :error-messages="headOfficeLocationErrors"
                      :counter="255"
                      required
                      @input="$v.HeadOfficeLocation.$touch()"
                      @blur="$v.HeadOfficeLocation.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      本社最寄駅
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="NearestStationToHeadOffice"
                      :error-messages="nearestStationToHeadOfficeErrors"
                      :counter="255"
                      required
                      @input="$v.NearestStationToHeadOffice.$touch()"
                      @blur="$v.NearestStationToHeadOffice.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      応募完了タグURL
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="https://xn--pckua2a7gp15o89zb.com/api/cv/12345/"
                      v-model="CompleteTagUrl"
                      :error-messages="completeTagUrlErrors"
                      :counter="1000"
                      required
                      @input="$v.ComplateTagUrl.$touch()"
                      @blur="$v.ComplateTagUrl.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      GoogleトラッキングID
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="G-123456"
                      v-model="Ga4TrackingId"
                      :error-messages="ga4TrackingIdErrors"
                      :counter="45"
                      @input="$v.Ga4TrackingId.$touch()"
                      @blur="$v.Ga4TrackingId.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mb-5">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      テーマカラー
                    </label>
                    <v-color-picker
                      v-model="ThemeColor"
                      hide-canvas
                      mode="rgba"
                      show-swatches
                      swatches-max-height="151"
                    ></v-color-picker>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      テーマカラー2
                    </label>
                    <v-color-picker
                      v-model="ThemeColor2"
                      hide-canvas
                      mode="rgba"
                      show-swatches
                      swatches-max-height="151"
                    ></v-color-picker>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      フォントカラー
                    </label>
                    <v-color-picker
                      v-model="FontThemeColor"
                      hide-canvas
                      mode="rgba"
                      show-swatches
                      swatches-max-height="151"
                    ></v-color-picker>
                  </v-col>
                </v-row>
                <v-row class="mb-5">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      詳細ボタンカラー
                    </label>
                    <v-color-picker
                      v-model="DetailButtonColor"
                      hide-canvas
                      mode="rgba"
                      show-swatches
                      swatches-max-height="151"
                    ></v-color-picker>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      電話で応募ボタンカラー
                    </label>
                    <v-color-picker
                      v-model="TelButtonColor"
                      hide-canvas
                      mode="rgba"
                      show-swatches
                      swatches-max-height="151"
                    ></v-color-picker>
                  </v-col>
                </v-row>
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      最終更新者
                    </label>
                    <v-text-field
                      outlined
                      class="font-size-input"
                      v-model="UpdatedByName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      最終更新日
                    </label>
                    <v-text-field
                      outlined
                      class="font-size-input"
                      v-model="UpdatedAt"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <div class="text-right">
                  <v-btn v-show="!isUpdate"
                    @click="submitCreate"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                    color="green"
                    :loading="loading"
                    large
                  >
                    作成
                  </v-btn>
                  <v-btn v-show="isUpdate"
                    @click="submitUpdate"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                    color="green"
                    :loading="loading"
                    large
                  >
                    更新
                  </v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import { mapActions } from "vuex";
import { required, maxLength, url } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { getLength } from "@/util/maxLengthValidation"
import Overlay from '../../../components/Overlay.vue';
import HeaderTopDashboard from "../../../components/HeaderTopDashboard";

const validDomain = (value) => {
  //　ハイフンで始まらない
  if (value.startsWith('-')) {
    return false;
  }
  
  //　ハイフンで終わらない
  if (value.endsWith('-')) {
    return false;
  }
  
  // 長さが1〜63文字
  if (value.length < 1 || value.length > 63) {
    return false;
  }
  
  // 文字はアルファベット、数字、ハイフンのみ
  const re = /^[A-Za-z0-9-]+$/;
  if (!re.test(value)) {
    return false;
  }
  
  return true;
};

export default {
  name: "CompanyDetail",
  components: {
    HeaderTopDashboard,
    Overlay
  },
  data() {
    return {
      CompanyId: "",
      CompanyName: "",
      CompanyTopTitle: "",
      Domain: "",
      CompanyWebsiteUrl: "",
      CompanyLogoFilename: "",
      CompanyLogoFileUrl: "",
      CompanyLogoFile: new FormData(),
      CorporatePhilosophy: "",
      Vision: "",
      HeadOfficeLocation: "",
      NearestStationToHeadOffice: "",
      ThemeColor: "#EECE00",
      ThemeColor2: "#EECE00",
      FontThemeColor: "#000000",
      DetailButtonColor: "#888888",
      TelButtonColor: "#0B93BE",
      CompleteTagUrl: "",
      Ga4TrackingId: "",
      UpdatedByName: "",
      UpdatedAt: "",
      loading: false,
      getLoading: false,
      isUpdate: false,
    }
  },
  mixins: [validationMixin],
  methods: {
    async uploadFile(file) {
      if (file) {
        const companyLogoFileUrl = URL.createObjectURL(file);
        this.CompanyLogoFileUrl = companyLogoFileUrl;
        this.CompanyLogoFilename = file.name;
        this.CompanyLogoFile = new FormData();
        this.CompanyLogoFile.append('CompanyLogoFile', file);
      } else if (!file) {
        this.CompanyLogoFileUrl = "";
        this.CompanyLogoFilename = "",
        this.CompanyLogoFile = "";
      }
    },
    async uploadMedia() {
      try {
        this.CompanyLogoFile.append("CompanyId", this.CompanyId);
        this.CompanyLogoFile.append("CompanyDomain", this.Domain);
        if(this.CompanyLogoFile.get("CompanyLogoFile")){
          await axios.post("/media/upload", this.CompanyLogoFile);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async submitCreate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios.post("/companies", {
        CompanyName: this.CompanyName,
        Domain: this.Domain,
        CompanyTopTitle: this.CompanyTopTitle,
        CompanyWebsiteUrl: this.CompanyWebsiteUrl,
        CompanyLogoFilename: this.CompanyLogoFilename,
        CorporatePhilosophy: this.CorporatePhilosophy.replace(/\n/g, '\n'),
        Vision: this.Vision.replace(/\n/g, '\n'),
        HeadOfficeLocation: this.HeadOfficeLocation,
        NearestStationToHeadOffice: this.NearestStationToHeadOffice,
        ThemeColor: this.ThemeColor,
        ThemeColor2: this.ThemeColor2,
        FontThemeColor: this.FontThemeColor,
        DetailButtonColor: this.DetailButtonColor,
        TelButtonColor: this.TelButtonColor,
        CompleteTagUrl: this.CompleteTagUrl,
        Ga4TrackingId: this.Ga4TrackingId,
        UpdatedBy: 1,
      }).then(async(res) => {
        this.companyId = res.data.CompanyId;
        await this.uploadMedia();
        this.openSnackbarSuccess();
        this.$router.push({ name: "Companies" });
      }).catch(e => {
        const res = e.response;
        if (res && res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    async submitUpdate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      await this.uploadMedia();
      axios.put("/companies/" + this.CompanyId, {
        CompanyId: this.CompanyId,
        CompanyName: this.CompanyName,
        CompanyTopTitle: this.CompanyTopTitle,
        Domain: this.Domain,
        CompanyWebsiteUrl: this.CompanyWebsiteUrl,
        CompanyLogoFilename: this.CompanyLogoFilename,
        CorporatePhilosophy: this.CorporatePhilosophy.replace(/\n/g, '\n'),
        Vision: this.Vision.replace(/\n/g, '\n'),
        HeadOfficeLocation: this.HeadOfficeLocation,
        NearestStationToHeadOffice: this.NearestStationToHeadOffice,
        ThemeColor: this.ThemeColor,
        ThemeColor2: this.ThemeColor2,
        FontThemeColor: this.FontThemeColor,
        DetailButtonColor: this.DetailButtonColor,
        TelButtonColor: this.TelButtonColor,
        CompleteTagUrl: this.CompleteTagUrl,
        Ga4TrackingId: this.Ga4TrackingId,
        UpdatedBy: 2,
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({ name: "Companies" });
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    ...mapActions("snackbar", ["openSnackbarSuccess", "openSnackbarApiError", "openSnackbarValidationError"]),
  },
  validations: {
    CompanyName: { required, maxLength: maxLength(255) },
    Domain: { required, maxLength: maxLength(50), validDomain },
    CompanyTopTitle: { required, maxLength: maxLength(255) },
    CompanyWebsiteUrl: { maxLength: maxLength(1000), url },
    CompanyLogoFileUrl: { required },
    CorporatePhilosophy: { maxLength: maxLength(1000) },
    Vision: { maxLength: maxLength(10000) },
    HeadOfficeLocation: { maxLength: maxLength(255) },
    NearestStationToHeadOffice: { maxLength: maxLength(255) },
    CompleteTagUrl: { maxLength: maxLength(1000) },
    Ga4TrackingId: { maxLength: maxLength(45) },
  },
  computed: {
    companyNameErrors() {
      const errors = [];
      if (!this.$v.CompanyName.$dirty) return errors;
      !this.$v.CompanyName.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.CompanyName.required && errors.push("入力必須項目です");
      return errors;
    },
    companyTopTitleErrors() {
      const errors = [];
      if (!this.$v.CompanyTopTitle.$dirty) return errors;
      !this.$v.CompanyTopTitle.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.CompanyTopTitle.required && errors.push("入力必須項目です");
      return errors;
    },
    domainErrors() {
      const errors = [];
      if (!this.$v.Domain.$dirty) return errors;
      !this.$v.Domain.maxLength && errors.push("50文字以内で入力してください");
      !this.$v.Domain.required && errors.push("入力必須項目です");
      !this.$v.Domain.validDomain && errors.push("形式に誤りがあります。ハイフンを除く記号や全角文字は利用できません");
      return errors;
    },
    companyWebsiteUrlErrors() {
      const errors = [];
      if (!this.$v.CompanyWebsiteUrl.$dirty) return errors;
      !this.$v.CompanyWebsiteUrl.maxLength && errors.push("1000文字以内で入力してください");
      !this.$v.CompanyWebsiteUrl.url && errors.push("URL形式で入力してください");
      return errors;
    },
    companyLogoFilenameErrors() {
      const errors = [];
      if (!this.$v.CompanyLogoFileUrl.$dirty) return errors;
      !this.$v.CompanyLogoFileUrl.required && errors.push("入力必須項目です");
      return errors;
    },
    corporatePhilosophyErrors() {
      const errors = [];
      if (!this.$v.CorporatePhilosophy.$dirty) return errors;
      !this.$v.CorporatePhilosophy.maxLength && errors.push("1000文字以内で入力してください");
      return errors;
    },
    visionErrors() {
      const errors = [];
      if (!this.$v.Vision.$dirty) return errors;
      !this.$v.Vision.maxLength && errors.push("10000文字以内で入力してください");
      return errors;
    },
    headOfficeLocationErrors() {
      const errors = [];
      if (!this.$v.HeadOfficeLocation.$dirty) return errors;
      !this.$v.HeadOfficeLocation.maxLength && errors.push("255文字以内で入力してください");
      return errors;
    },
    nearestStationToHeadOfficeErrors() {
      const errors = [];
      if (!this.$v.NearestStationToHeadOffice.$dirty) return errors;
      !this.$v.NearestStationToHeadOffice.maxLength && errors.push("255文字以内で入力してください");
      return errors;
    },
    completeTagUrlErrors() {
      const errors = [];
      if (!this.$v.CompleteTagUrl.$dirty) return errors;
      !this.$v.CompleteTagUrl.maxLength && errors.push("1000文字以内で入力してください");
      return errors;
    },
    ga4TrackingIdErrors() {
      const errors = [];
      if (!this.$v.Ga4TrackingId.$dirty) return errors;
      !this.$v.Ga4TrackingId.maxLength && errors.push("45文字以内で入力してください");
      return errors;
    },
  },
  async mounted() {
    if (this.$route.params.companyId) {
      this.getLoading = true;
      this.isUpdate = true;
      axios.get("/companies/" + this.$route.params.companyId).then(res => {

        const s3BaseUrl = process.env.NODE_ENV === "production" ?
            `https://${res.data.Domain}.tt-recruit.com/img/` :
            `${process.env.VUE_APP_PREVIEW_ENDPOINT}/${res.data.Domain}/img/`;
        
        this.CompanyId = res.data.CompanyId;
        this.CompanyName = res.data.CompanyName;
        this.Domain = res.data.Domain;
        this.CompanyTopTitle = res.data.CompanyTopTitle;
        this.CompanyWebsiteUrl = res.data.CompanyWebsiteUrl;
        this.CompanyLogoFilename = res.data.CompanyLogoFilename;
        this.CompanyLogoFileUrl = s3BaseUrl + res.data.CompanyLogoFilename;
        this.CorporatePhilosophy = res.data.CorporatePhilosophy;
        this.Vision = res.data.Vision;
        this.HeadOfficeLocation = res.data.HeadOfficeLocation;
        this.NearestStationToHeadOffice = res.data.NearestStationToHeadOffice;
        this.ThemeColor = res.data.ThemeColor;
        this.ThemeColor2 = res.data.ThemeColor2;
        this.FontThemeColor = res.data.FontThemeColor;
        this.TelButtonColor = res.data.TelButtonColor;
        this.CompleteTagUrl = res.data.CompleteTagUrl;
        this.Ga4TrackingId = res.data.Ga4TrackingId;
        this.UpdatedByName = res.data.UpdatedByName;
        this.UpdatedAt = dayjs(res.data.UpdatedAt).format("YYYY-MM-DD HH:mm:ss");
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        } else {
          console.log(e.response)
          this.openSnackbarApiError();
        }
      }).finally(() => {
        this.getLoading = false;
      });
    }
  }
}
</script>

<style scoped>
.custom-file-input >>> .v-input__append-inner,
.custom-file-input >>> .v-input__prepend-outer {
  margin: 10px 5px;
}

.image-styles {
  max-width: 100%;
  height: 300px;
  width: 100%;
  object-fit: cover;
}
</style>