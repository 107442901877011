<template>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "index",
  methods: {
    ...mapGetters("loginUser", ["getLoginUser"]),
  },
  created() {
    const companyId = this.getLoginUser().CompanyId;
    if (!companyId) {
      location.href = "/login";
    }
    if (companyId === 1) {
      this.$router.push({ name: "Companies" });
    } else {
      this.$router.push({ path: companyId + "/admin/candidates" });
    }
  }
}
</script>

<style scoped>

</style>