<template>
  <div>
    <header-top-dashboard primary is-admin>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
    <v-card class="card-shadow">
      <div class="card-header-padding card-border-bottom">
        <div class="font-weight-600 text-h3 text-typo mb-1">
          企業一覧
        </div>
        <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
          新しく企業を追加する場合は、画面右手の企業の追加ボタンをクリックしてください。
        </p>
      </div>
      <v-dialog width="538" v-model="deleteDialog">
        <template v-slot:default="dialog">
          <v-card class="card-shadow bg-gradient-danger">
            <div
              class="card-header-padding d-flex align-center justify-space-between pb-10"
            >
              <v-card-title
                class="pa-0 text-h3 font-weight-600 text-white"
              >注意</v-card-title
              >
              <v-btn
                icon
                @click="dialog.value = false"
                elevation="0"
                :ripple="false"
                height="38"
                min-width="38"
                width="38"
                class="font-weight-600 text-capitalize btn-ls btn-hover rounded-sm"
                color="rgba(255,255,255,.6)"
              >
                <v-icon size="13">fas fa-times</v-icon>
              </v-btn>
            </div>
            <v-card-text class="card-padding pt-6 text-center">
              <v-icon size="48" class="text-white">
                ni-bell-55
              </v-icon>
              <h4
                class="heading mt-6 text-white font-weight-600 text-uppercase"
              >
                企業ID {{deleteCompanyId}} を削除しようとしています
              </h4>
              <p
                class="text-white font-size-root font-weight-thin mt-3"
              >
                削除された企業は元に戻せません。<br>本当に削除してよろしいですか？
              </p>
            </v-card-text>
            <div
              class="card-padding d-flex align-center justify-space-between"
            >
              <v-btn
                elevation="0"
                max-width="136"
                :ripple="false"
                height="43"
                class="font-weight-600 text-capitalize btn-secondary py-3 px-6 rounded-sm"
                color="#fff"
                @click="deleteCompany"
                :loading="deleteLoading"
              >
                はい、削除します
              </v-btn>
              <v-btn
                @click="dialog.value = false"
                elevation="0"
                max-width="136"
                :ripple="false"
                height="43"
                class="font-weight-600 text-capitalize text-white py-3 px-6 rounded-sm"
                color="transparent"
              >
                閉じる
              </v-btn>
            </div>
          </v-card>
        </template>
      </v-dialog>
      <v-card-text class="px-0 py-0">
        <v-data-table
          :header-props="{'sort-icon': 'fa-sort-up'}"
          :headers="headers"
          :items="companies"
          :search="search"
          :loading="loading"
          loading-text="データを取得しています... Please wait"
          class="table thead-light text-body"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat height="80">
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    placeholder="Search"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem">
                        fas fa-search
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>

              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="text-capitalize btn-primary py-2 px-4 rounded-sm ms-3"
                color="teal"
                @click="$router.push({ path: 'companies/create'});"
              >
                <v-icon class="mr-2">fa-plus</v-icon>
                企業を追加
              </v-btn>
            </v-toolbar>
          </template>

          <template v-slot:item.CompanyId="{ item }">
            {{ item.CompanyId }}
          </template>

          <template v-slot:item.Domain="{ item }">
            <a :href="'https://' + item.Domain + '.tt-recruit.com'" target="_blank">{{ item.Domain }}</a>
          </template>

          <template v-slot:item.UpdatedAt="{ item }">
            {{ $dayjs(item.UpdatedAt).format("YYYY-MM-DD HH:mm:ss") }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="$router.push({ path: '/' + item.CompanyId + '/admin'});"
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="font-weight-600 text-capitalize btn-ls btn-warning me-2 my-2 rounded-sm text--secondary"
                  outlined
                  v-on="on"
                >
                  <v-icon size="16">mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>企業の管理画面へ遷移します</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="$router.push({ path: 'companies/' + item.CompanyId});"
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="ml-4 font-weight-600 text-capitalize btn-ls btn-warning me-2 my-2 rounded-sm"
                  outlined
                  v-on="on"
                  color="cyan"
                >
                  <v-icon size="16">mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>編集画面へ遷移します</span>
            </v-tooltip>
          </template>

          <template v-slot:item.deleteAction="{ item }">
            <v-btn
              @click="openDeleteDialog(item.CompanyId)"
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="font-weight-600 text-capitalize btn-ls btn-danger me-2 my-2 rounded-sm"
              color="red"
              outlined
            >
              <v-icon size="16">mdi-delete-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="12" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#46f3c4"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    </v-container>
  </div>
</template>

<script>
import HeaderTopDashboard from "../../../components/HeaderTopDashboard";
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "Companies",
  components: {
    HeaderTopDashboard
  },
  data() {
    return {
      loading: false,
      deleteCompanyId: 0,
      deleteDialog: false,
      deleteLoading: false,
      companies: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      checkboxModel: [],
      search: "",
      filteredData : "",
      headers: [
        { text: "アクション", value: "actions", sortable: false },
        {
          text: "企業ID",
          align: "start",
          value: "CompanyId",
        },
        { text: "企業名", value: "CompanyName" },
        { text: "ドメイン", value: "Domain" },
        { text: "最終更新者", value: "UpdatedByName" },
        { text: "更新日時", value: "UpdatedAt" },
        { text: "削除", value: "deleteAction", sortable: false },
      ],
    };
  },
  watch: {
      search(val) {
        this.filteredData = { ...this.filteredData, search: val }
        this.$router.push({query: this.filteredData });
      },
      page(newPage) {
      this.filteredData = { ...this.filteredData, page: newPage }
      this.$router.push({query: this.filteredData });
    },
  },
  methods: {
    openDeleteDialog(companyId) {
      this.deleteDialog = true;
      this.deleteCompanyId = companyId;
    },
    deleteCompany() {
      this.deleteLoading = true;
      axios.delete("/companies/" + this.deleteCompanyId).then(() => {
        this.openSnackbarSuccess();
        this.loadCompanies();
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.deleteLoading = false;
        this.deleteDialog = false;
        this.deleteCompanyId = 0;
      });
    },
    loadCompanies() {
      this.loading = true;
      axios.get("/companies").then(res => {
        this.companies = res.data;
        this.page = this.$route.query.page ? Number(this.$route.query.page) : this.page;
      }).finally(() => {
        this.loading = false;
      });
    },
    ...mapActions("snackbar", ["openSnackbarSuccess", "openSnackbarApiError", "openSnackbarValidationError"])
  },
  mounted() {
    this.search = this.$route.query.search || '';
  },
  created() {
    this.loadCompanies();
  }
};

</script>

<style scoped>

</style>