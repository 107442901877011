<template>
  <div>
    <header-top-dashboard primary is-admin>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <Overlay :loading="getLoading" />
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                <span v-show="!isUpdate">ユーザー作成</span>
                <span v-show="isUpdate">ユーザー更新</span>
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="12" md="8">
                  <p class="font-size-root text-body font-weight-thin" v-show="!isUpdate">
                    新しいユーザーを登録します。メールアドレスはシステム全体で一つしか登録できません。<br>
                    使いたいメールアドレスが既に登録されている場合は、システム担当者にご連絡ください。<br>
                  </p>
                  <p class="font-size-root text-body font-weight-thin" v-show="isUpdate">
                    新しいユーザーを更新します。メールアドレスはシステム全体で一つしか登録できません。<br>
                    使いたいメールアドレスが既に登録されている場合は、システム担当者にご連絡ください。<br>
                  </p>
                </v-col>
              </v-row>
              <div class="border-bottom my-6"></div>

              <v-form class="mt-13">
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >ユーザーID</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="UserId"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>ユーザー名</label
                    >
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="例）三田　三夫"
                        v-model="Username"
                        :error-messages="usernameErrors"
                        :counter="255"
                        required
                        @input="$v.Username.$touch()"
                        @blur="$v.Username.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>所属企業</label
                    >
                    <CompanySelector
                        v-model="Company"
                        :initial-value="initialCompanyId"
                        :error-messages="companyErrors"
                        @change="$v.Company.$touch()"
                        @blur="$v.Company.$touch()"
                    >
                    </CompanySelector>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>メールアドレス</label
                    >
                    <v-text-field
                        outlined
                        type="email"
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="例）example@333-inc.com"
                        v-model="ActiveEmail"
                        :error-messages="activeEmailErrors"
                        :counter="255"
                        required
                        @input="$v.ActiveEmail.$touch()"
                        @blur="$v.ActiveEmail.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span v-show="!isUpdate" class="red--text">* </span>パスワード</label
                    >
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        type="password"
                        v-model="Password"
                        :error-messages="passwordErrors"
                        :counter="255"
                        required
                        @input="$v.Password.$touch()"
                        @blur="$v.Password.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >最終更新者</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="UpdatedByName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >最終更新日</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="UpdatedAt"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <div class="text-right">
                  <v-btn v-show="!isUpdate"
                         @click="submitCreate"
                         elevation="0"
                         :ripple="false"
                         height="43"
                         class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                         color="green"
                         :loading="loading"
                         large
                  >作成</v-btn
                  >
                  <v-btn v-show="isUpdate"
                         @click="submitUpdate"
                         elevation="0"
                         :ripple="false"
                         height="43"
                         class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                         color="green"
                         :loading="loading"
                         large
                  >更新</v-btn
                  >
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength, email, numeric } from "vuelidate/lib/validators";
import dayjs from "dayjs";
import Overlay from '../../../components/Overlay.vue';
import HeaderTopDashboard from "../../../components/HeaderTopDashboard";
import CompanySelector from "../../../components/Selectors/CompanySelector";

export default {
  name: "UserDetail",
  components: {
    CompanySelector,
    HeaderTopDashboard,
    Overlay
  },
  data() {
    return {
      UserId: "",
      initialCompanyId: null,
      Company: null,
      Username: "",
      ActiveEmail: "",
      Password: "",
      UpdatedByName: "",
      UpdatedAt: "",
      loading: false,
      getLoading: false,
      isUpdate: false,
    }
  },
  mixins: [validationMixin],
  methods: {
    submitCreate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios.post("/users", {
        CompanyId: Number(this.Company.CompanyId),
        Username: this.Username,
        ActiveEmail: this.ActiveEmail,
        Password: this.Password,
        UpdatedBy: 1,
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({ name: "Users" });
      }).catch(e => {
        const res = e.response;
        if (res && res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    submitUpdate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios.put("/users/" + this.UserId, {
        UserId: this.UserId,
        CompanyId: Number(this.Company.CompanyId),
        Username: this.Username,
        ActiveEmail: this.ActiveEmail,
        Password: this.Password,
        UpdatedBy: 2,
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({ name: "Users" });
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    ...mapActions("snackbar", ["openSnackbarSuccess", "openSnackbarApiError", "openSnackbarValidationError"]),
  },
  validations() {
    if (!this.isUpdate) {
      return {
        Username: {required, maxLength: maxLength(255)},
        Company: {required},
        ActiveEmail: {required, email, maxLength: maxLength(255)},
        Password: {required},
      }
    }
    return {
      Username: {required, maxLength: maxLength(255)},
      Company: {required},
      ActiveEmail: {required, email, maxLength: maxLength(255)},
      Password: {},
    }
  },
  computed: {
    usernameErrors() {
      const errors = [];
      if (!this.$v.Username.$dirty) return errors;
      !this.$v.Username.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.Username.required && errors.push("入力必須項目です");
      return errors;
    },
    companyErrors() {
      const errors = [];
      if (!this.$v.Company.$dirty) return errors;
      !this.$v.Company.required && errors.push("入力必須項目です");
      return errors;
    },
    activeEmailErrors() {
      const errors = [];
      if (!this.$v.ActiveEmail.$dirty) return errors;
      !this.$v.ActiveEmail.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.ActiveEmail.email && errors.push("メールアドレスの書式に誤りがあります");
      !this.$v.ActiveEmail.required && errors.push("入力必須項目です");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.Password.$dirty) return errors;
      !this.$v.Password.required && !this.isUpdate && errors.push("入力必須項目です");
      return errors;
    },
  },
  mounted() {
    if (this.$route.params.userId) {
      this.getLoading = true;
      this.isUpdate = true;
      axios.get("/users/" + this.$route.params.userId).then(res => {
        this.UserId = res.data.UserId;
        this.initialCompanyId = res.data.CompanyId;
        this.Username = res.data.Username;
        this.ActiveEmail = res.data.ActiveEmail;
        this.UpdatedByName = res.data.UpdatedByName;
        this.UpdatedAt = dayjs(res.data.UpdatedAt).format("YYYY-MM-DD HH:mm:ss");
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.getLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>