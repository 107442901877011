<template>
  <v-overlay :value="loading" z-index="51" opacity="0.8">
    <div class="loader-style">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span class="loading-text">データを読み込んでいます</span>
    </div>
  </v-overlay>
</template>
<style scoped>
.tag {
  width: 25px;
}
</style>
<script>
export default {
  name: "overlay",
  props: {
    loading: {
      type: Boolean,
      default: null,
    },
  },
};
</script>
