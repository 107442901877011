<template>
  <div>
    <header-top-dashboard primary>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <Overlay :loading="getLoading" />
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                <span v-show="isUpdate">メールテンプレート更新</span>
                <span v-show="!isUpdate">メールテンプレート作成</span>
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="12" md="8">
                  <p class="font-size-root text-body font-weight-thin">
                    メールテンプレートを更新します。<br>
                  </p>
                </v-col>
              </v-row>
              <div class="border-bottom my-6"></div>

              <v-form class="mt-13">
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >会社ID</label
                    >
                    <v-text-field
                      outlined
                      class="font-size-input"
                      v-model="CompanyId"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >メールテンプレートタイプ</label
                    >
                    <v-text-field
                      outlined
                      class="font-size-input"
                      v-model="MailType"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>
                      タイトル
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="MailTitle"
                      :error-messages="mailTitleErrors"
                      required
                      :counter="255"
                      @input="$v.MailTitle.$touch()"
                      @blur="$v.MailTitle.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>本文</label
                    >
                    <v-row>
                      <v-col cols="12" md="10">
                        <v-select
                          outlined
                          placeholder="プレースホルダーを選択して本文に追加"
                          class="input-style font-size-input text-light-input placeholder-light"
                          v-model="placeholder"
                          :items="placeholderItem"
                          item-text="text"
                          item-value="value"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-btn
                          outlined
                          color="cyan"
                          @click="addPlaceholder"
                        >
                          <v-icon left>
                            mdi-format-annotation-plus
                          </v-icon>
                          追加
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-textarea
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="MailBody"
                      :counter="4000"
                      :error-messages="mailBodyErrors"
                      required
                      rows="20"
                      @input="MailBody.$touch()"
                      @blur="MailBody.$touch()"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      最終更新者
                    </label>
                    <v-text-field
                      outlined
                      class="font-size-input"
                      v-model="UpdatedByName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      最終更新日
                    </label>
                    <v-text-field
                      outlined
                      class="font-size-input"
                      v-model="UpdatedAt"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <div class="text-right">
                  <v-btn v-show="!isUpdate"
                    @click="submitCreate"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                    color="green"
                    :loading="loading"
                    large
                  >
                    作成
                  </v-btn>
                  <v-btn
                    v-show="isUpdate"
                    @click="submitUpdate"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                    color="green"
                    :loading="loading"
                    large
                  >
                    更新
                  </v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import dayjs from "dayjs";
import HeaderTopDashboard from "../../../components/HeaderTopDashboard";
import Overlay from '../../../components/Overlay.vue';

export default {
  name: "MailTemplateDetail",
  components: {
    HeaderTopDashboard,
    Overlay
  },
  data() {
    return {
      MailTemplateId: "",
      CompanyId: "",
      MailType: "",
      MailTitle: "",
      MailBody: "",
      UpdatedByName: "",
      UpdatedAt: "",
      loading: false,
      getLoading: false,
      isUpdate: false,
      placeholder: "",
      placeholderItem: [
        { value: "{{.Request.StoreName}}", text: "店舗名" },
        { value: "{{.Request.Name}}", text: "応募者名"},
        { value: "{{.Request.Url}}", text: "記事URL"},
        { value: "{{.Request.DisplayTitle}}", text: "記事名"},
      ],
    }
  },
  mixins: [validationMixin],
  methods: {
    addPlaceholder() {
      this.MailBody += this.placeholder;
    },
    submitCreate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios.post("/mail-templates", {
        CompanyId: parseInt(this.CompanyId),
        MailType: this.MailType,
        MailTitle: this.MailTitle,
        MailBody: this.MailBody,
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({ name: "MailTemplates" });
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    submitUpdate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios.put("/mail-templates/" + this.MailTemplateId, {
        MailTemplateId: this.MailTemplateId,
        CompanyId: this.CompanyId,
        MailType: this.MailType,
        MailTitle: this.MailTitle,
        MailBody: this.MailBody,
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({ name: "MailTemplates" });
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    ...mapActions("snackbar", ["openSnackbarSuccess", "openSnackbarApiError", "openSnackbarValidationError"]),
  },
  validations: {
    MailTitle: { required, maxLength: maxLength(255) },
    MailBody: { required, maxLength: maxLength(4000) },
  },
  computed: {
    mailTitleErrors() {
      const errors = [];
      if (!this.$v.MailTitle.$dirty) return errors;
      !this.$v.MailTitle.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.MailTitle.required && errors.push("入力必須項目です");
      return errors;
    },
    mailBodyErrors() {
      const errors = [];
      if (!this.$v.MailBody.$dirty) return errors;
      !this.$v.MailBody.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.MailBody.required && errors.push("入力必須項目です");
      return errors;
    },
  },
  mounted() {
    if (this.$route.params.mailTemplateId) {
      this.getLoading = true;
      this.isUpdate = true
      axios.get("/mail-templates/" + this.$route.params.mailTemplateId).then(res => {
        this.MailTemplateId = res.data.MailTemplateId;
        this.CompanyId = res.data.CompanyId;
        this.MailType = res.data.MailType;
        this.MailTitle = res.data.MailTitle;
        this.MailBody = res.data.MailBody;
        this.UpdatedByName = res.data.UpdatedByName;
        this.UpdatedAt = dayjs(res.data.UpdatedAt).format("YYYY-MM-DD HH:mm:ss");
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.getLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>