const getters = {
    getLoginUser: () => {
        return state;
    }
};

let state = {
    UserId: null,
    Username: "",
    ActiveEmail: "",
    CompanyId: "",
    CompanyName: "",
};
const saveState = JSON.parse(localStorage.getItem("user"));
if (saveState !== null) {
    state = saveState;
}

const mutations = {
    setLoginUser: (state, data) => {
        state.UserId = data.UserId;
        state.Username = data.Username;
        state.ActiveEmail = data.ActiveEmail;
        state.CompanyId = data.CompanyId;
        state.CompanyName = data.CompanyName;
    },
};

const actions = {
    setLoginUser: ({ commit }, data) => {
        localStorage.setItem("user", JSON.stringify(data));
        commit("setLoginUser", data);
    },
    logout: ({ commit }, data) => {
        localStorage.removeItem("user");
    }
};

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions,
};