<template>
  <div>
    <header-top-dashboard primary> </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <v-overlay :value="getLoading" z-index="51" opacity="0.8">
        データを読み込んでいます
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-card class="card-shadow mb-6">
        <div class="card-header-padding card-border-bottom">
          <div class="d-flex align-center justify-space-between flex-wrap">
            <p class="font-weight-700 text-h1 text-dark mb-1">{{ Name }}</p>
            <p class="font-weight-600 text-h2 label-color mb-1">
              {{ Kana }}
            </p>
            <p class="font-size-root text-body font-weight-thin">
              -応募日時： {{ ApplyAt }}
            </p>
            <div>
              <p class="font-size-root text-body font-weight-thin mb-0">
                ステータス
              </p>
              <v-select
                outlined
                class="input-style input-style-status font-size-input-sm text-light-input placeholder-light"
                density="compact"
                v-model="Status"
                :key="Status"
                @change="updateStatus($event)"
                :items="statusItem"
                item-text="text"
                item-value="value"
                persistent-hint
                :hide-spin-buttons="true"
              >
                <template slot="prepend-inner">
                  <v-progress-circular
                    v-show="loading"
                    indeterminate
                    :size="15"
                    :width="1"
                    color="green"
                  ></v-progress-circular>
                </template>
              </v-select>
            </div>
          </div>
        </div>

        <v-card-text class="card-padding">
          <v-row>
            <v-col align="right" class="py-0">
              <v-btn
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class="font-weight-600 text-capitalize btn-ls btn-warning me-2 my-2 rounded-sm"
                outlined
                v-on="on"
                color="cyan"
                @click="$router.push({ name: 'CandidateUpdate' })"
              >
                <v-icon size="16">mdi-square-edit-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <span class="font-size-root text-body font-weight-thin">
                雇用形態
              </span>
              <span class="mx-2">:</span>
              <span class="font-size-root text-body font-weight-thin">
                {{ EmploymentStatus }}
              </span>
            </v-col>
            <v-col cols="12" md="6">
              <span class="font-size-root text-body font-weight-thin">
                都道府県
              </span>
              <span class="mx-2"> : </span>
              <span class="font-size-root text-body font-weight-thin">
                {{ Pref }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <span class="font-size-root text-body font-weight-thin">
                職種
              </span>
              <span class="mx-2"> : </span>
              <span class="font-size-root text-body font-weight-thin">
                {{ Occupation }}
              </span>
            </v-col>
            <v-col cols="12" md="6">
              <span class="font-size-root text-body font-weight-thin">
                住所
              </span>
              <span class="mx-2"> : </span>
              <span class="font-size-root text-body font-weight-thin">
                {{ City }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <span class="font-size-root text-body font-weight-thin">
                店舗
              </span>
              <span class="mx-2"> : </span>
              <span class="font-size-root text-body font-weight-thin">
                {{ Store }}
              </span>
            </v-col>
            <v-col cols="12" md="6">
              <span class="font-size-root text-body font-weight-thin">
                候補者備考
              </span>
              <span class="mx-2"> : </span>
              <span class="font-size-root text-body font-weight-thin">
                {{ Note }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <span class="font-size-root text-body font-weight-thin">
                メールアドレス
              </span>
              <span class="mx-2"> : </span>
              <span class="font-size-root text-body font-weight-thin">
                {{ Email }}
              </span>
            </v-col>
            <v-col cols="12" md="6"> </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <span class="font-size-root text-body font-weight-thin">
                電話番号
              </span>
              <span class="mx-2"> : </span>
              <span class="font-size-root text-body font-weight-thin">
                {{ Tel }}
              </span>
            </v-col>
            <v-col cols="12" md="6"> </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <span class="font-size-root text-body font-weight-thin">
                生年月日
              </span>
              <span class="mx-2"> : </span>
              <span class="font-size-root text-body font-weight-thin">
                {{ Birth }}
              </span>
            </v-col>

            <v-col cols="12" md="6">
              <span class="mr-4">
                <span class="font-size-root text-body font-weight-thin">
                  紹介
                </span>
                <span class="mx-2"> : </span>
                <span class="font-size-root text-body font-weight-thin">
                  {{ Referrer }}
                </span>
              </span>

              <span class="font-size-root text-body font-weight-thin">
                紹介者
              </span>
              <span class="mx-2"> : </span>
              <span class="font-size-root text-body font-weight-thin">
                {{ Referral }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <span class="font-size-root text-body font-weight-thin">
                性別
              </span>
              <span class="mx-2"> : </span>
              <span class="font-size-root text-body font-weight-thin">
                {{ Gender }}
              </span>
            </v-col>

            <v-col cols="12" md="6">
              <span class="mr-4">
                <span class="font-size-root text-body font-weight-thin">
                  応募方法
                </span>
                <span class="mx-2"> : </span>
                <span class="font-size-root text-body font-weight-thin">
                  WEB
                </span>
              </span>

              <span>
                <span class="font-size-root text-body font-weight-thin">
                  応募URL
                </span>
                <span class="mx-2"> : </span>
                <span class="font-size-root text-body font-weight-thin">
                  {{ ApplyUrl }}
                </span>
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="card-shadow mb-6">
        <v-tabs bg-color="primary">
          <v-tab value="one" @click="setTab('one')">通知</v-tab>
          <v-tab value="two" @click="setTab('two')">メッセージ</v-tab>
          <div class="create-msg-btn">
            <v-btn
              elevation="0"
              :ripple="false"
              height="30"
              class="text-capitalize btn-primary py-2 px-4 rounded-sm ms-3"
              color="teal"
              @click="sendEmail"
            >
              <v-icon class="mr-2">fa-plus</v-icon>
              メッセージを作成する
            </v-btn>
          </div>
        </v-tabs>

        <v-window>
          <v-window-item v-if="tab === 'one'">
            <div class="mb-2" v-for="n in 4" :key="n">
              <div class="card-header-padding card-border-bottom">
                <span class="font-size-root text-body font-weight-thin">
                  <span class="mr-4"> ⭐ </span>
                  <span>
                    山田さんがステータスを面接日程調整待ちから一次面接に変更しました。
                  </span>
                </span>
              </div>
            </div>
          </v-window-item>
          <v-window-item v-if="tab === 'two'">
            <div class="mb-2" div v-for="n in 4" :key="n">
              <div class="card-header-padding card-border-bottom">
                <span class="font-size-root text-body font-weight-thin">
                  <span class="mr-4"> ⭐ </span>
                  <span>
                    山田さんがステータスを面接日程調整待ちから一次面接に変更しました。
                  </span>
                </span>
              </div>
            </div>
          </v-window-item>
        </v-window>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import HeaderTopDashboard from "../../../components/HeaderTopDashboard";
import StoreSelector from "../../../components/Selectors/StoreSelector";
import PrefSelector from "../../../components/Selectors/PrefSelector";
import axios from "axios";
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import dayjs from "dayjs";
import CANDIDATE_STATUS from "@/const/candidateItem.js";

const REFERRAL_FALSE = 1;
const REFERRAL_TRUE = 2;

export default {
  name: "CandidateDetail",
  components: {
    PrefSelector,
    StoreSelector,
    HeaderTopDashboard,
  },
  data() {
    return {
      tab: "one",
      CandidateId: "",
      ArticleId: null,
      Article: null,
      Status: 0,
      statusItem: CANDIDATE_STATUS.status,
      receivingMethodItem: [
        { value: 1, text: "WEB" },
        { value: 2, text: "電話" },
        { value: 3, text: "メール" },
        { value: 9, text: "その他" },
      ],
      EmploymentStatus: "",
      Occupation: "",
      Store: null,
      Name: "",
      Kana: "",
      Email: "",
      Tel: "",
      Birth: null,
      Gender: "",
      Pref: "",
      City: "",
      Note: "",
      Referral: null,
      Referrer: "",
      ApplyUrl: "",
      Memo: "",
      Age: null,
      ReceivingMethod: null,
      ApplyAt: null,
      UpdatedByName: "",
      UpdatedAt: "",
      loading: false,
      getLoading: false,
      isUpdate: false,
      menu: false,
      pickerDate: "1995-1-1",
    };
  },
  mixins: [validationMixin],
  watch: {
    menu(val) {
      val &&
        setTimeout(
          () => (
            (this.$refs.picker.activePicker = "YEAR"),
            // 選び始めたら初期化
            (this.pickerDate = null)
          )
        );
    },
  },
  methods: {
    setTab(tab) {
      this.tab = tab;
    },
    sendEmail() {
      document.location = "mailto:" + this.Email;
    },
    async updateStatus(e) {
      let status = e;
      this.loading = true;
      try {
        await axios.put("/candidates/" + this.CandidateId, {
          CandidateId: this.CandidateId,
          Status: status,
        });
        this.openSnackbarSuccess();
      } catch (e) {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data);
        }
      } finally {
        this.loading = false;
      }
    },
    ...mapActions("snackbar", [
      "openSnackbarSuccess",
      "openSnackbarApiError",
      "openSnackbarValidationError",
    ]),
  },
  async mounted() {
    if (this.$route.params.candidateId) {
      this.getLoading = true;
      this.isUpdate = true;
      try {
        const res = await axios.get(
          "/candidates/" + this.$route.params.candidateId
        );
        this.CandidateId = res.data.CandidateId;
        this.Status = res.data.Status;
        this.EmploymentStatus = res.data.EmploymentStatus;
        this.Occupation = res.data.Occupation;
        this.Store = res.data.Store.StoreName;
        this.Name = res.data.Name;
        this.Kana = res.data.Kana;
        this.Email = res.data.Email;
        this.Tel = res.data.Tel;
        this.Birth = dayjs(res.data.Birth).format("YYYY-MM-DD");
        this.Gender = res.data.Gender;
        this.Pref = res.data.Pref;
        this.City = res.data.City;
        this.Note = res.data.Note;
        this.Referral = res.data.Referral === REFERRAL_TRUE ? 1 : 0;
        this.Referrer = res.data.Referrer;
        this.ApplyUrl = res.data.ApplyUrl;
        this.Memo = res.data.Memo;
        this.ReceivingMethod = res.data.ReceivingMethod;
        this.Age = res.data.Age;
        this.ApplyAt = dayjs(res.data.ApplyAt).format("YYYY-MM-DD　HH:mm:ss");
        this.UpdatedByName = res.data.UpdatedByName;
        this.UpdatedAt = dayjs(res.data.UpdatedAt).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      } catch (e) {
        console.log(e);
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data);
        }
      } finally {
        this.getLoading = false;
      }
    }
  },
};
</script>

<style scoped>
.input-style-status {
  width: 190px;
  height: 50px;
  border-radius: 20px;
}
.create-msg-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
