<template>
    <div>
      <header-top-dashboard primary>
      </header-top-dashboard>
      <v-container fluid class="pt-0 px-6 mt-n13">
        <Overlay :loading="getLoading"/>
        <v-row>
          <v-col class="mt-n3">
            <v-card class="card-shadow mb-6">
              <div class="card-header-padding card-border-bottom">
                <div class="font-weight-600 text-h3 text-typo mb-1">
                  <span v-show="!isUpdate">インタビュー作成</span>
                  <span v-show="isUpdate">インタビュー更新</span>
                </div>
              </div>
  
              <v-card-text class="card-padding">
                <v-row>
                  <v-col cols="12" md="8">
                    <p class="font-size-root text-body font-weight-thin" v-show="!isUpdate">
                      新しいインタビューを登録します。<br>
                    </p>
                    <p class="font-size-root text-body font-weight-thin" v-show="isUpdate">
                      インタビュー内容を更新します。<br>
                    </p>
                  </v-col>
                </v-row>
                <div class="border-bottom my-6"></div>
  
                <v-form class="mt-13">
                  <v-row v-show="isUpdate">
                    <v-col cols="12" md="6" class="py-0">
                      <label
                          class="label-color font-weight-600 mb-2 d-block"
                      >インタビューID</label
                      >
                      <v-text-field
                          outlined
                          class="font-size-input"
                          v-model="InterviewId"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6" class="py-0">
                      <div>
                        <label
                        class="label-color font-weight-600 mb-2 d-block"
                      ><span class="red--text">* </span>画像名</label>
                        <v-input
                          :error-messages="interviewImageFilenameErrors"
                        >
                        <v-file-input
                        type="file"
                        outlined
                          class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                          :placeholder="(isUpdate || isDuplicate) && ImageFilename ? '' :'例）image'"
                          accept="image/*" 
                          hide-details
                          required
                          @change="(e) => uploadFile(e, 'interviewImage')"
                          @input="$v.InterviewImageUrl.$touch()"
                          @blur="$v.InterviewImageUrl.$touch()"
                        >
                        <template v-if="(isUpdate || isDuplicate) && ImageFilename &&  InterviewImage.entries().next().done" #prepend-inner>
                          {{ ImageFilename }}
                        </template>
                        </v-file-input>
                        </v-input>
                        </div>
                        <div class="d-flex justify-center  mb-2" v-if="InterviewImageUrl">
                          <img :src="InterviewImageUrl" alt="Uploaded Image" class="image-styles" />
                        </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      ><span class="red--text">* </span>タイトル</label
                      >
                      <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder='例）社員（ホールスタッフ）　21歳 新卒 入社3年目'
                        v-model="Title"
                        :error-messages="interviewDetailTitleErrors"
                        required
                        :counter="255"
                        @input="$v.Title.$touch()"
                        @blur="$v.Title.$touch()"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="12" class="py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >QA</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="py-0">
                      <div v-if="Qa && Qa.length > 0">
                        <v-card class="card-shadow mb-6 pt-6 " v-for="(qa, qKey) in $v.Qa.$each.$iter" :key="qKey">
                          <v-card-text class="card-padding">
                            <v-row>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>質問</label
                                >
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder='例）仕事でやりがいを感じるときは？'
                                  v-model="qa.Question.$model"
                                  :error-messages="qaQuestionErrors(qKey)"
                                  required
                                  :counter="255"
                                  @input="qa.Question.$touch()"
                                  @blur="qa.Question.$touch()"
                                >
                                </v-text-field>
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                >
                                  並び順
                                </label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  type="number"
                                  v-model.number="qa.OrderNum.$model"
                                  :error-messages="qaOrderNumErrors(qKey)"
                                  @input="qa.OrderNum.$touch()"
                                  @blur="qa.OrderNum.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>回答</label>
                                <v-input
                                    class="mb-6"
                                    :error-messages="qaAnswerErrors(qKey)"
                                  >
                                  <vue-editor
                                  outlined
                                  class="black--text mb-6"
                                  :editorToolbar="customToolbarDetail"
                                  placeholder="働くこと自体が初めてでしたが、
お客様に顔を覚えてもらうようになったときにやりがいを感じます。"
                                  v-model="qa.Answer.$model"
                                  :counter="30000"
                                  required
                                  @input="qa.Answer.$touch()"
                                  @blur="qa.Answer.$touch()"
                                ></vue-editor>
                                </v-input>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  right
                                  top
                                  color="red"
                                  @click="deleteQa(qKey)"
                                >
                                  <v-icon>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="py-0 my-0">
                      <v-btn
                        tile
                        outlined
                        color="green"
                        @click="addQa()"
                      >
                        <v-icon left>
                          mdi-table-row-plus-after
                        </v-icon>
                        QA欄の追加
                      </v-btn>
                    </v-col>
                  </v-row>
          
                  <div class="text-right">
                    <v-btn v-show="!isUpdate"
                           @click="submitCreate"
                           elevation="0"
                           :ripple="false"
                           height="43"
                           class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                           color="green"
                           :loading="loading"
                           large
                    >作成
                    </v-btn
                    >
                    <v-btn v-show="isUpdate"
                           @click="submitUpdate"
                           elevation="0"
                           :ripple="false"
                           height="43"
                           class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                           color="green"
                           :loading="loading"
                           large
                    >更新
                    </v-btn
                    >
                  </div>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
  import Vue from "vue";
  import { VueEditor } from "vue2-editor";
  import axios from "axios";
  import {mapActions} from "vuex";
  import { validationMixin } from "vuelidate";
  import { required, maxLength , numeric} from "vuelidate/lib/validators";
  import HeaderTopDashboard from "../../../components/HeaderTopDashboard";
  import Overlay from '../../../components/Overlay.vue';
  import dayjs from "dayjs";
  import CUSTOM_TOOLBAR from "@/const/richTextToolsBar.js";
  import { getLength } from "@/util/maxLengthValidation";

  Vue.use(VueEditor);
 
  export default {
    name: "InterviewDetail",
    components: {
      HeaderTopDashboard,
      Overlay,
      VueEditor,
    },
    data() {
      return {
        customToolbarDetail: CUSTOM_TOOLBAR.info,
        key: "",  // キー情報を格納するプロパティ
        qKey: "",  // 別のキー情報を格納するプロパティ
        InterviewId: "",
        ImageFilename: "", //
        InterviewImage: new FormData(),
        InterviewImageUrl : "",
        Title: "",
        Qa: [{
          QaId: "",
          InterviewId: "",
          OrderNum: 1,
          Question: "",
          Answer: "",
        }],
        CompanyId: "",
        CompanyDomain: "",
        loading: false,
        getLoading: false,
        isUpdate: false,
        isDuplicate: false,
      }
    },
    mixins: [validationMixin],
    methods: {
      uploadFile(file, type) {
        this.VideoFileInvalid = false;
        if (file && type == "interviewImage") {
          const interviewImageUrl = URL.createObjectURL(file);
          this.ImageFilename = file.name;
          this.InterviewImage = new FormData(); // Reset formData
          this.InterviewImage.append('InterviewImage', file);
          this.InterviewImageUrl = interviewImageUrl;
        }
        else if(!file && type == "interviewImage") {
          this.ImageFilename = "";
          this.InterviewImage = "";
          this.InterviewImageUrl = "";
        }
      },
    addQa() {
      this.Qa.push({
        OrderNum: this.Qa.length + 1,
        Question: "",
        Answer: "",
        QaId: "",
        InterviewId: "",
      });
    },
    deleteQa(qKey) {
      this.Qa.splice(qKey, 1);
    },
    async submitCreate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$vuetify.goTo(0);
        this.openSnackbarError("入力エラーがあります。入力内容を確認してください。");
        return;
      }
      this.loading = true;
      await this.uploadMedia();

      await axios.post("/interview/create", {
        ImageFilename: this.ImageFilename, //
        Title: this.Title,
        Qa: this.Qa.map((qa) => {
          return {
            OrderNum: qa.OrderNum,
            Question: qa.Question,
            Answer: qa.Answer,
          }
        }),
        CompanyId: parseInt(this.$route.params.companyId),
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({name: "Interviews"});
      }).catch(e => {
        const res = e.response;
        if (res && res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
      },
    async submitUpdate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$vuetify.goTo(0);
        this.openSnackbarError("入力エラーがあります。入力内容を確認してください。");
        return;
      }
      this.loading = true;
      await this.uploadMedia();
      axios.put("/interview/update", {
        InterviewId: this.InterviewId,
        ImageFilename: this.ImageFilename, //
        Title: this.Title,
        Qa: this.Qa.map((qa) => {
          return {
            OrderNum: qa.OrderNum,
            Question: qa.Question,
            Answer: qa.Answer,
            QaId: parseInt(qa.QaId),
            InterviewId: parseInt(qa.InterviewId),
          }
        }),
        CompanyId: parseInt(this.$route.params.companyId),
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({name: "Interviews"});
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });

    },
    ...mapActions("snackbar", ["openSnackbarSuccess", "openSnackbarApiError", "openSnackbarValidationError"]),
    qaOrderNumErrors(qKey) {
      const errors = [];
      if (!this.$v.Qa.$each[qKey].OrderNum.$dirty) return errors;
      !this.$v.Qa.$each[qKey].OrderNum.numeric && errors.push("数値を入力してください");
      return errors;
    },
    qaQuestionErrors(qKey) {
      const errors = [];
      if (!this.$v.Qa.$each[qKey].Question.$dirty) return errors;
      !this.$v.Qa.$each[qKey].Question.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.Qa.$each[qKey].Question.required && errors.push("入力必須項目です");
      return errors;
    },
    qaAnswerErrors(qKey) {
      const errors = [];
      if (!this.$v.Qa.$each[qKey].Answer.$dirty) return errors;
      const content = this.$v.Qa.$each[qKey].Answer.$model;
      const length = getLength(content);
      if (length > 30000) {
          errors.push("30000文字以内で入力してください");
      }
      !this.$v.Qa.$each[qKey].Answer.required && errors.push("入力必須項目です");
      return errors;
    },
    async uploadMedia() {
      try{
        const allAllMediaData = new FormData();
        allAllMediaData.append("CompanyId", this.CompanyId);
        allAllMediaData.append("CompanyDomain", this.CompanyDomain);
        
        if(this.InterviewImage.get("InterviewImage")){
          allAllMediaData.append("InterviewImage", this.InterviewImage.get("InterviewImage"));
        }
        await axios.post("/media/upload" , allAllMediaData);
      }
      catch(e){
        console.log(e);
      }
      finally{}
    },

    },
    validations() {
      return {
        InterviewImageUrl : { required },
        Title: {required, maxLength: maxLength(255)},
        Qa: {
          $each: {
            OrderNum: {numeric},
            Question: {required, maxLength: maxLength(255)},
            Answer: {required},
          }
        },
      }
    },
    computed: {
      interviewImageFilenameErrors() {
        const errors = [];
        if (!this.$v.InterviewImageUrl.$dirty) return errors;
        !this.$v.InterviewImageUrl.required && errors.push("画像をアップロードしてください");
        return errors;
      },
      interviewDetailTitleErrors() {
        const errors = [];
        if (!this.$v.Title.$dirty) return errors;
        !this.$v.Title.maxLength && errors.push("255文字以内で入力してください");
        !this.$v.Title.required && errors.push("入力必須項目です");
        return errors;
      },
      questionErrors() {
        const errors = [];
        if (!this.$v.Question.$dirty) return errors;
        !this.$v.Question.maxLength && errors.push("255文字以内で入力してください");
        return errors;
      },
      answerErrors() {
        const errors = [];
        if (!this.$v.Answer.$dirty) return errors;
        !this.$v.Answer.maxLength && errors.push("30000文字以内で入力してください");
        return errors;
      },
    },
    async mounted() {
      if (this.$route.params.interviewsId) {
        this.getLoading = true;
        this.isUpdate = true;
        await axios.get("/interviews/" + this.$route.params.interviewsId).then(async res => {
          const s3BaseUrl = process.env.NODE_ENV === "production" ? 
            `https://${res.data.Company.Domain}.tt-recruit.com/img/` :
            `${process.env.VUE_APP_PREVIEW_ENDPOINT}/${res.data.Company.Domain}/img/`;
          this.CompanyId = this.$route.params.companyId;
          this.CompanyDomain = res.data.Company.Domain;
          this.InterviewId = res.data.InterviewId;
          this.ImageFilename = res.data.ImageFilename;
          this.InterviewImage = new FormData();
          this.InterviewImageUrl = s3BaseUrl + res.data.ImageFilename;
          this.Title = res.data.Title;
          this.Qa = (res.data.Qa && res.data.Qa.length > 0) ?
            res.data.Qa.map((Qa) => {
              return {
                QaId: Qa.QaId,
                InterviewId: Qa.InterviewId,
                OrderNum: Qa.OrderNum,
                Question: Qa.Question,
                Answer: Qa.Answer,
              }
            }) : [];
        }).catch(e => {
          const res = e.response || {}; // e.responseがundefinedの場合に備える
          if (res.status === 400) {
            this.openSnackbarValidationError(res.data);
          } else {
            console.error("エラーが発生しました:", e); // エラーをコンソールに出力
          }
        }).finally(() => {
          this.getLoading = false;
        });
      } else if(!this.$route.params.articleId){
        this.CompanyId = this.$route.params.companyId;
        const res = await axios.get("/companies/" + this.$route.params.companyId);
        this.CompanyDomain = res.data.Domain;
      }
    }
  }
  </script>
  
  <style scoped>
  .custom-file-input >>> .v-input__append-inner,
  .custom-file-input >>> .v-input__prepend-outer {
    margin: 10px 5px;
  }
  
  .image-styles {
    max-width: 100%;
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
  </style>
  