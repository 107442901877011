<template>
  <v-app>
  <router-view></router-view>
  <v-snackbar v-model="$store.state.snackbar.snackbar" timeout="5000" :color="$store.state.snackbar.color" top right>
    <!-- Snackbar内に表示するテキスト -->
    <div class="d-flex align-start alert-notify">
      <v-icon size="24" class="text-white mr-5"
      >ni ni-bell-55</v-icon
      >
      <p class="mb-0">
                    <span class="font-size-root font-weight-600"
                    >{{ $store.state.snackbar.title }}</span
                    >
        <br />
        {{ $store.state.snackbar.text }}
      </p>
    </div>

    <template v-slot:action="{ attrs }">
      <v-btn
          icon
          elevation="0"
          max-width="136"
          :ripple="false"
          height="43"
          class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
          color="rgba(255,255,255, .85)"
          @click="closeSnackbar()"
          v-bind="attrs"
      >
        <v-icon size="13">fas fa-times</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("snackbar", ["closeSnackbar"]),
    setMeta(route) {
      // タイトルを設定
      if (route.meta.displayName) {
        const setTitle = route.meta.displayName;
        document.title = "TP - " + setTitle;
      }
    }
  },
  mounted() {
    let route = this.$route;
    this.setMeta(route);
  },
  watch: {
    '$route'(route, from) {
      this.setMeta(route);
    }
  }
};
</script>
