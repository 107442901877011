import Vue from "vue";
import Vuex from "vuex";
import snackbar from "./modules/snackbar";
import loginUser from "./modules/loginUser";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        snackbar,
        loginUser,
    },
});