<template>
  <div>
    <header-top-dashboard primary is-admin>
      <v-row class="mt-0">
        <v-col
            v-for="(item, i) in miniCards"
            :key="i"
            cols="12"
            lg="4"
            md="9"
            class="py-0"
        >
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-stats-padding">
              <v-row no-gutters>
                <v-col>
                  <div
                      class="font-weight-600 text-muted text-uppercase text-h5"
                  >
                    {{ item.subtitle }}
                  </div>
                  <p class="font-weight-600 text-h2 text-typo mb-0">
                    {{ item.title }}
                  </p>
                </v-col>
                <v-col class="text-right">
                  <v-avatar :color="item.gradient">
                    <v-icon size="20" class="text-white">
                      {{ item.icon }}
                    </v-icon>
                  </v-avatar>
                </v-col>
              </v-row>

              <p class="mt-4 mb-0 text-body font-weight-light">
                <span class="text-success me-2">3.48%</span> Since last month
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <v-row class="mt-0">
        <v-col lg="4" class="pt-6">
          <notification-list></notification-list>
        </v-col>
        <v-col lg="8" class="pt-6">
          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <p class="font-weight-600 text-typo text-h4 mb-0">登録企業数の推移</p>
            </div>
            <v-card-text class="card-padding">
              <div class="chart-area">
                <canvas :height="350" :id="salesChartID"></canvas>
              </div>
            </v-card-text>
          </v-card>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeaderTopDashboard from "../../components/HeaderTopDashboard";
import NotificationList from "../Widgets/NotificationList";
import {
  salesChart,
} from "@/components/Charts/Chart"

export default {
  name: "AdminDashboard",
  components: {
    HeaderTopDashboard,
    NotificationList,
  },
  data() {
    return {
      salesChartID: "salesChart",
      miniCards: [
        {
          subtitle: "登録企業数",
          title: "12",
          gradient: "bg-gradient-danger",
          icon: "ni-active-40",
        },
        {
          subtitle: "登録ユーザー数",
          title: "30",
          gradient: "bg-gradient-warning",
          icon: "ni-chart-pie-35",
        },
        {
          subtitle: "解約率",
          title: "1.65%",
          gradient: "bg-gradient-success",
          icon: "ni-chart-bar-32",
        },
      ],
    }
  },
  mounted() {
    salesChart.createChart(this.salesChartID);
  }
}
</script>

<style scoped>

</style>