import Vue from "vue";
import axios from "axios";
import dayjs from "dayjs";

import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import store from "./store/index"
import "./const/quillTagSetting.js";

Vue.config.productionTip = false;

// plugin setup
Vue.use(DashboardPlugin);

// set dayjs
dayjs.locale("ja");
Vue.prototype.$dayjs = dayjs;

// set axios
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT

// set error handling
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    router.push('/login', () => {});
    store.dispatch('snackbar/openSnackbarValidationError', {
      Message: ["認証の有効期限が切れました。再度ログインしてください。"]
    }).then();
  }
  if (error.response.status === 403) {
    store.dispatch('snackbar/openSnackbarValidationError', {
      Message: ["アクセス権限を保有していないため表示できません。"]
    }).then();
  }
  if (error.response.status >= 500) {
    store.dispatch('snackbar/openSnackbarApiError', {}).then();
    console.log(error);
  }
  return Promise.reject(error)
})

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
