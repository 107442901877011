<template>
  <v-dialog
    width="538"
    v-model="bulkDeleteDialog"
    @click:outside="closeBulkDelete"
  >
    <template>
      <v-card class="card-shadow bg-gradient-danger">
        <div
          class="card-header-padding d-flex align-center justify-space-between pb-10"
        >
          <v-card-title class="pa-0 text-h3 font-weight-600 text-white"
            >確認</v-card-title
          >
          <v-btn
            icon
            @click="closeBulkDelete"
            elevation="0"
            :ripple="false"
            height="38"
            min-width="38"
            width="38"
            class="font-weight-600 text-capitalize btn-ls btn-hover rounded-sm"
            color="rgba(255,255,255,.6)"
          >
            <v-icon size="13">fas fa-times</v-icon>
          </v-btn>
        </div>
        <v-card-text class="card-padding pt-6 text-center">
          <v-icon size="48" class="text-white">ni-bell-55</v-icon>
          <h4 class="heading mt-6 text-white font-weight-600 text-uppercase">
            記事ID
            <template v-for="(s, index) in selected">
              <template v-if="index > 0">, </template>
              {{ s.ArticleId }} </template
            >を削除しようとしています
          </h4>
          <p class="text-white font-size-root font-weight-thin mt-3">
            記事に紐付く情報も全て削除されます。<br />
            本当に削除してよろしいですか？
          </p>
        </v-card-text>
        <div class="card-padding d-flex align-center justify-space-between">
          <v-btn
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize btn-secondary py-3 px-6 rounded-sm"
            color="#fff"
            @click="$emit('bulkDeleteArticle')"
            :loading="bulkDeleteLoading"
            >はい、削除します</v-btn
          >
          <v-btn
            @click="closeBulkDelete"
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize text-white py-3 px-6 rounded-sm"
            color="transparent"
            >閉じる</v-btn
          >
        </div>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "bulkDeleteModal",
  props: {
    bulkDeleteDialog: {
      type: String,
      default: "",
    },
    bulkDeleteLoading: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["bulkDeleteArticle", "closeBulkDeleteDialog"],

  methods: {
    closeBulkDelete() {
      this.$emit("closeBulkDeleteDialog");
    },
  },
};
</script>

<style scoped></style>
