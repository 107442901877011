<template>
  <div>
    <header-top-dashboard primary>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <Overlay :loading="getLoading" />
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                <span v-show="!isUpdate">媒体連携追加</span>
                <span v-show="isUpdate">媒体連携更新</span>
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="12" md="8">
                  <p class="font-size-root text-body font-weight-thin" v-show="!isUpdate">
                    新しい媒体を連携します。<br>
                  </p>
                  <p class="font-size-root text-body font-weight-thin" v-show="isUpdate">
                    媒体連携を更新します。<br>
                  </p>
                </v-col>
              </v-row>
              <div class="border-bottom my-6"></div>

              <v-form class="mt-13">
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >媒体連携ID</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="CompanyMediumId"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>連携媒体名</label>
                    <v-select
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        v-model="MediumId"
                        :items="mediumItem"
                        item-text="text"
                        item-value="value"
                        persistent-hint
                        :error-messages="mediumIdErrors"
                        @input="$v.MediumId.$touch()"
                        @blur="$v.MediumId.$touch()"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>店舗</label>
                    <StoreSelector
                        ref="store"
                        v-model="Store"
                        :error-messages="storeErrors"
                        @change="$v.Store.$touch()"
                        @blur="$v.Store.$touch()"
                    >
                    </StoreSelector>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>ログインID</label
                    >
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="例）媒体ログインID"
                        v-model="LoginId"
                        :error-messages="loginIdErrors"
                        :counter="255"
                        required
                        @input="$v.LoginId.$touch()"
                        @blur="$v.LoginId.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mb-6">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>ログインパスワード</label
                    >
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="例）媒体ログインパスワード"
                        v-model="LoginPassword"
                        :error-messages="loginPasswordErrors"
                        :counter="255"
                        required
                        @input="$v.LoginPassword.$touch()"
                        @blur="$v.LoginPassword.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >最終更新者</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="UpdatedByName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >最終更新日</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="UpdatedAt"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <div class="text-right">
                  <v-btn v-show="!isUpdate"
                         @click="submitCreate"
                         elevation="0"
                         :ripple="false"
                         height="43"
                         class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                         color="green"
                         :loading="loading"
                         large
                  >作成</v-btn
                  >
                  <v-btn v-show="isUpdate"
                         @click="submitUpdate"
                         elevation="0"
                         :ripple="false"
                         height="43"
                         class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                         color="green"
                         :loading="loading"
                         large
                  >更新</v-btn
                  >
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength, numeric } from "vuelidate/lib/validators";
import dayjs from "dayjs";
import HeaderTopDashboard from "../../../components/HeaderTopDashboard";
import Overlay from '../../../components/Overlay.vue';
import StoreSelector from "../../../components/Selectors/StoreSelector.vue";

export default {
  name: "CompanyMediumDetail",
  components: {
    StoreSelector,
    HeaderTopDashboard,
    Overlay
  },
  data() {
    return {
      statusItem: [
        { value: 1, text: "連携停止" },
        { value: 2, text: "連携中"},
        { value: 3, text: "連携エラー"},
        { value: 4, text: "データ取得中"},
      ],
      //TODO Medium Selector可する
      mediumItem: [
        { value: "INSCOM", text: "飲食店ドットコム"}
      ],
      CompanyMediumId: "",
      Store: null,
      StoreId: null,
      MediumId: "",
      Status: null,
      LoginId: "",
      LoginPassword: "",
      UpdatedByName: "",
      UpdatedAt: "",
      loading: false,
      getLoading: false,
      isUpdate: false,
    }
  },
  mixins: [validationMixin],
  methods: {
    submitCreate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios.post("/company-mediums", {
        CompanyId: Number(this.$route.params.companyId),
        StoreId: this.Store?.StoreId,
        MediumId: this.MediumId,
        Status: this.Status,
        LoginId: this.LoginId,
        LoginPassword: this.LoginPassword,
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({ name: "CompanyMediums" });
      }).catch(e => {
        const res = e.response;
        if (res && res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    submitUpdate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios.put("/company-mediums/" + this.CompanyMediumId, {
        CompanyMediumId: this.CompanyMediumId,
        CompanyId: Number(this.$route.params.companyId),
        StoreId: this.Store?.StoreId,
        MediumId: this.MediumId,
        Status: this.Status,
        LoginId: this.LoginId,
        LoginPassword: this.LoginPassword,
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({ name: "CompanyMediums" });
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    ...mapActions("snackbar", ["openSnackbarSuccess", "openSnackbarApiError", "openSnackbarValidationError"]),
  },
  validations: {
    LoginId: { required, maxLength: maxLength(255) },
    LoginPassword: { required, maxLength: maxLength(255) },
    Store: { required },
    MediumId: { required },
  },
  computed: {
    loginIdErrors() {
      const errors = [];
      if (!this.$v.LoginId.$dirty) return errors;
      !this.$v.LoginId.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.LoginId.required && errors.push("入力必須項目です");
      return errors;
    },
    loginPasswordErrors() {
      const errors = [];
      if (!this.$v.LoginPassword.$dirty) return errors;
      !this.$v.LoginPassword.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.LoginPassword.required && errors.push("入力必須項目です");
      return errors;
    },
    storeErrors() {
      const errors = [];
      if (!this.$v.Store.$dirty) return errors;
      !this.$v.Store.required && errors.push("入力必須項目です");
      return errors;
    },
    mediumIdErrors() {
      const errors = [];
      if (!this.$v.MediumId.$dirty) return errors;
      !this.$v.MediumId.required && errors.push("入力必須項目です");
      return errors;
    },
  },
  async mounted() {
    if (this.$route.params.companyMediumId) {
      this.getLoading = true;
      this.isUpdate = true;
      try {
        const res = await axios.get("/company-mediums/" + this.$route.params.companyMediumId);
        this.CompanyMediumId = res.data.CompanyMediumId;
        this.Store = res.data.Store.StoreId;
        this.MediumId = res.data.MediumId;
        this.Status = res.data.Status;
        const promiseStore = this.$refs.store.setInitialValue(res.data.Store.StoreId);
        await Promise.all([promiseStore]);

        this.LoginId = res.data.LoginId;
        this.LoginPassword = res.data.LoginPassword;
        this.UpdatedByName = res.data.UpdatedByName;
        this.UpdatedAt = dayjs(res.data.UpdatedAt).format("YYYY-MM-DD HH:mm:ss");
      } catch(e) {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      } finally {
        this.getLoading = false;
      }
    }
  }
}
</script>

<style scoped>

</style>