<template>
  <div>
    <header-top-dashboard primary>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <Overlay :loading="getLoading" />
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                <span v-show="!isUpdate">連絡先作成</span>
                <span v-show="isUpdate">連絡先更新</span>
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="12" md="8">
                  <p class="font-size-root text-body font-weight-thin" v-show="!isUpdate">
                    新しい連絡先を登録します。<br>
                  </p>
                  <p class="font-size-root text-body font-weight-thin" v-show="isUpdate">
                    連絡先を更新します。<br>
                  </p>
                </v-col>
              </v-row>
              <div class="border-bottom my-6"></div>

              <v-form class="mt-13">
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >連絡先ID</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="ContactId"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>連絡先名</label
                    >
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="例）トリプルスリー武蔵小杉店"
                        v-model="ContactName"
                        :error-messages="contactNameErrors"
                        :counter="255"
                        required
                        @input="$v.ContactName.$touch()"
                        @blur="$v.ContactName.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>連絡先メールアドレス</label
                    >
                    <v-combobox
                        v-model="Email"
                        placeholder='例）triple.page@333-inc.com'
                        class="input-style font-size-input text-light-input placeholder-light"
                        multiple
                        :error-messages="emailErrors"
                        chips
                        deletable-chips
                        outlined
                        dense
                        @input="$v.Email.$touch()"
                        @blur="$v.Email.$touch()"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>連絡先電話番号</label
                    >
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder='例）08012345678'
                        v-model="Tel"
                        :error-messages="telErrors"
                        required
                        :counter="12"
                        @input="$v.Tel.$touch()"
                        @blur="$v.Tel.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >最終更新者</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="UpdatedByName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >最終更新日</label
                    >
                    <v-text-field
                        outlined
                        class="font-size-input"
                        v-model="UpdatedAt"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <div class="text-right">
                  <v-btn v-show="!isUpdate"
                         @click="submitCreate"
                         elevation="0"
                         :ripple="false"
                         height="43"
                         class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                         color="green"
                         :loading="loading"
                         large
                  >作成</v-btn
                  >
                  <v-btn v-show="isUpdate"
                         @click="submitUpdate"
                         elevation="0"
                         :ripple="false"
                         height="43"
                         class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                         color="green"
                         :loading="loading"
                         large
                  >更新</v-btn
                  >
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import {required, maxLength, numeric, minLength, email} from "vuelidate/lib/validators";
import dayjs from "dayjs";
import HeaderTopDashboard from "../../../components/HeaderTopDashboard";
import Overlay from '../../../components/Overlay.vue';

export default {
  name: "ContactDetail",
  components: {
    HeaderTopDashboard,
    Overlay
  },
  data() {
    return {
      ContactId: "",
      ContactName: "",
      Email: "",
      Tel: "",
      UpdatedByName: "",
      UpdatedAt: "",
      loading: false,
      getLoading: false,
      isUpdate: false,
    }
  },
  mixins: [validationMixin],
  methods: {
    submitCreate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios.post("/contacts", {
        ContactName: this.ContactName,
        CompanyId: Number(this.$route.params.companyId),
        Email: this.Email?.join(";"),
        Tel: this.Tel,
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({ name: "Contacts" });
      }).catch(e => {
        const res = e.response;
        if (res && res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    submitUpdate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios.put("/contacts/" + this.ContactId, {
        ContactId: this.ContactId,
        ContactName: this.ContactName,
        CompanyId: Number(this.$route.params.companyId),
        Email: this.Email?.join(";"),
        Tel: this.Tel,
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({ name: "Contacts" });
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    ...mapActions("snackbar", ["openSnackbarSuccess", "openSnackbarApiError", "openSnackbarValidationError"]),
  },
  validations: {
    ContactName: { required, maxLength: maxLength(255) },
    Tel: { required, maxLength: maxLength(255) },
    Email: {
      required,
      minLength: minLength(1),
      $each: {
        maxLength: maxLength(255), email
      },
    }
  },
  computed: {
    contactNameErrors() {
      const errors = [];
      if (!this.$v.ContactName.$dirty) return errors;
      !this.$v.ContactName.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.ContactName.required && errors.push("入力必須項目です");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.Email.$dirty) return errors;
      if (!this.$v.Email.required) {
        errors.push("入力必須項目です");
      }
      for (let k in this.$v.Email.$each.$iter) {
        if (!this.$v.Email.$each[k].email) {
          errors.push("メールアドレスの形式で入力してください");
          break;
        }
      }
      return errors;
    },
    telErrors() {
      const errors = [];
      if (!this.$v.Tel.$dirty) return errors;
      !this.$v.Tel.maxLength && errors.push("45文字以内で入力してください");
      !this.$v.Tel.required && errors.push("入力必須項目です");
      return errors;
    },
  },
  mounted() {
    if (this.$route.params.contactId) {
      this.getLoading = true;
      this.isUpdate = true;
      axios.get("/contacts/" + this.$route.params.contactId).then(res => {
        this.ContactId = res.data.ContactId;
        this.ContactName = res.data.ContactName;
        this.Email = res.data.Email.split(";").filter(Boolean);
        this.Tel = res.data.Tel;
        this.UpdatedByName = res.data.UpdatedByName;
        this.UpdatedAt = dayjs(res.data.UpdatedAt).format("YYYY-MM-DD HH:mm:ss");
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.getLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>