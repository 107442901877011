<template>
  <div>
    <header-top-dashboard primary>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <v-card class="card-shadow">
        <div class="card-header-padding card-border-bottom">
          <div class="mb-5">
            <div class="font-weight-600 text-h3 text-typo mb-1">
              メールテンプレート一覧
            </div>
            <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
              メールテンプレートを編集することができます。
            </p>
          </div>
        </div>
        <v-dialog width="538" v-model="deleteDialog">
          <template v-slot:default="dialog">
            <v-card class="card-shadow bg-gradient-danger">
              <div
                class="card-header-padding d-flex align-center justify-space-between pb-10"
              >
                <v-card-title
                  class="pa-0 text-h3 font-weight-600 text-white"
                >
                  注意
                </v-card-title>
                <v-btn
                  icon
                  @click="dialog.value = false"
                  elevation="0"
                  :ripple="false"
                  height="38"
                  min-width="38"
                  width="38"
                  class="font-weight-600 text-capitalize btn-ls btn-hover rounded-sm"
                  color="rgba(255,255,255,.6)"
                >
                  <v-icon size="13">fas fa-times</v-icon>
                </v-btn>
              </div>
              <v-card-text class="card-padding pt-6 text-center">
                <v-icon size="48" class="text-white">
                  ni-bell-55
                </v-icon>
                <h4
                  class="heading mt-6 text-white font-weight-600 text-uppercase"
                >
                  メールテンプをID {{ deleteMailTemplateId }} {{ deleteMailTemplateName }} を削除しようとしています
                </h4>
                <p
                  class="text-white font-size-root font-weight-thin mt-3"
                >
                  メールテンプをに紐付く求人情報も全て削除されます。<br>
                  削除されたメールテンプをは元に戻せません。<br>
                  本当に削除してよろしいですか？
                </p>
              </v-card-text>
              <div
                class="card-padding d-flex align-center justify-space-between"
              >
                <v-btn
                  elevation="0"
                  max-width="136"
                  :ripple="false"
                  height="43"
                  class="font-weight-600 text-capitalize btn-secondary py-3 px-6 rounded-sm"
                  color="#fff"
                  @click="deleteMailTemplate"
                  :loading="deleteLoading"
                >はい、削除します</v-btn
                >
                <v-btn
                  @click="dialog.value = false"
                  elevation="0"
                  max-width="136"
                  :ripple="false"
                  height="43"
                  class="font-weight-600 text-capitalize text-white py-3 px-6 rounded-sm"
                  color="transparent"
                >閉じる</v-btn
                >
              </div>
            </v-card>
          </template>
        </v-dialog>
        <v-card-text class="px-0 py-0">
          <v-data-table
            :header-props="{ 'sort-icon': 'fa-sort-up' }"
            :headers="headers"
            :items="mailTemplates"
            :search="search"
            :loading="loading"
            loading-text="データを取得しています... Please wait"
            class="table thead-light text-body"
            :page.sync="page"
            hide-default-footer
            @page-count="pageCount = $event"
            :items-per-page="itemsPerPage"
            mobile-breakpoint="0"
          >
            <template v-slot:top>
              <v-toolbar flat height="80">
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      hide-details
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                      height="46"
                      placeholder="名前"
                      v-model="name"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-spacer></v-spacer>

                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="text-capitalize btn-primary py-2 px-4 rounded-sm ms-3"
                  color="teal"
                  @click="$router.push({ path: 'mail-templates/create'});"
                >
                  <v-icon class="mr-2">fa-plus</v-icon>
                  メールテンプレートを追加
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.MailTemplateId="{ item }">
              {{ item.MailTemplateId }}
            </template>

            <template v-slot:item.MailBody="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="text-truncate"
                    style="max-width: 180px"
                  >
                    {{ item.MailBody }}
                  </div>
                </template>
                <pre>{{ item.MailBody }}</pre>
              </v-tooltip>
            </template>

            <template v-slot:item.UpdatedAt="{ item }">
              {{ $dayjs(item.UpdatedAt).format("YYYY-MM-DD HH:mm:ss") }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="
                      $router.push({
                        path: 'mail-templates/' + item.MailTemplateId,
                      })
                    "
                    elevation="0"
                    :ripple="false"
                    height="28"
                    min-width="36"
                    width="36"
                    class="font-weight-600 text-capitalize btn-ls btn-warning me-2 my-2 rounded-sm"
                    outlined
                    v-on="on"
                    color="cyan"
                  >
                    <v-icon size="16">mdi-square-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>編集画面へ遷移します</span>
              </v-tooltip>
            </template>
            <template v-slot:item.deleteAction="{ item }">
              <v-btn
                @click="openDeleteDialog(item.MailTemplateId, item.MailType)"
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class="font-weight-600 text-capitalize btn-ls btn-danger me-2 my-2 rounded-sm"
                color="red"
                outlined
              >
                <v-icon size="16">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="card-padding">
          <v-row>
            <v-col cols="12" class="ml-auto d-flex justify-end">
              <v-pagination
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination"
                color="#46f3c4"
                v-model="page"
                :length="pageCount"
                circle
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { debounce } from "@/util/debounce"
import HeaderTopDashboard from "@/components/HeaderTopDashboard";

export default {
  name: "MailTemplates",
  components: {
    HeaderTopDashboard
  },
  data() {
    return {
      loading: false,
      deleteMailTemplateId: 0,
      deleteMailTemplateName: "",
      deleteDialog: false,
      deleteLoading: false,
      mailTemplates: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      checkboxModel: [],
      search: "",
      filteredData: "",
      name: "",
      debouncedFetch : "",
      headers: [
        { text: "アクション", value: "actions", sortable: false },
        {
          text: "メールテンプレートID",
          align: "start",
          value: "MailTemplateId",
        },
        { text: "メールタイプ", value: "MailType" },
        { text: "タイトル", value: "MailTitle" },
        { text: "本文", value: "MailBody" },
        { text: "最終更新者", value: "UpdatedByName" },
        { text: "更新日時", value: "UpdatedAt" },
        { text: "削除", value: "deleteAction", sortable: false },
      ],
    };
  },
  watch: {
      name(nameVal) {
        this.filteredData = { ...this.filteredData, name: nameVal }
        this.$router.push({query: this.filteredData });
      },
      page(newPage) {
      this.filteredData = { ...this.filteredData, page: newPage }
      this.$router.push({query: this.filteredData });
    },
  },
  methods: {
    openDeleteDialog(mailTemplateId, mailTemplateName) {
      this.deleteDialog = true;
      this.deleteMailTemplateId = mailTemplateId;
      this.deleteMailTemplateName = mailTemplateName
    },
    deleteMailTemplate() {
      this.deleteLoading = true;
      axios.delete("/mail-templates/" + this.deleteMailTemplateId).then(() => {
        this.openSnackbarSuccess();
        this.loadMailTemplates();
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.deleteLoading = false;
        this.deleteDialog = false;
        this.deleteMailTemplateId = 0;
        this.deleteMailTemplateName = "";
      });
    },
    loadMailTemplates() {
      this.loading = true;
      let url = "/mail-templates?companyId=" + this.$route.params.companyId;

      if (this.name) {
        url += "&title=" + this.name;
      }
      axios
        .get(url)
        .then((res) => {
          this.mailTemplates = res.data;
          this.page = this.$route.query.page ? Number(this.$route.query.page) : this.page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    ...mapActions("snackbar", [
      "openSnackbarSuccess",
      "openSnackbarApiError",
      "openSnackbarValidationError",
    ]),
  },
  mounted() {
    this.name = this.$route.query.name || '';
    this.loadMailTemplates();
  },
  created() {
    this.debouncedFetch = debounce(this.loadMailTemplates, 500);
  },
};

</script>

<style scoped>

</style>