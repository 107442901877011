<template>
  <v-autocomplete class="input-style"
                  v-model="model"
                  :items="items"
                  :loading="isLoading"
                  :search-input.sync="search"
                  :readonly="readonly"
                  :error-messages="errorMessages"
                  hide-no-data
                  no-filter
                  item-text="StoreName"
                  item-value="StoreId"
                  :placeholder="placeholder"
                  return-object
                  outlined
                  append-icon="fa-sort-up"
                  @change="change"
                  @blur="blur"
  >
    <template slot="prepend-inner">
      <v-progress-circular
          v-show="isLoading"
          indeterminate
          :size="15"
          :width="1"
          color="green"
      ></v-progress-circular>
    </template>
    <template slot='selection' slot-scope='{ item }'>
      {{ item.StoreId }} - {{ item.StoreName }}
    </template>
    <template slot='item' slot-scope='{ item }'>
      {{ item.StoreId }} - {{ item.StoreName }}
    </template>
  </v-autocomplete>
</template>

<script>
import Axios from "axios";

export default {
  name: "StoreSelector",
  props: {
    'initialValue': {
    },
    'readonly': {
    },
    'placeholder': {
      type: String,
      default: '店舗名を入力して検索'
    },
    'errorMessages': {

    },
  },
  data: () => ({
    entries: [],
    isLoading: false,
    model: null,
    search: null,
    timerId: null,
  }),
  computed: {
    items() {
      return this.entries;
    },
  },
  methods: {
    change() {
      this.$emit('change');
    },
    blur() {
      this.$emit('blur');
    },
    async setInitialValue (value) {
      if (value) {
        // 初期値設定
        this.isLoading = true
        let response = await Axios.get("/stores/" + value)
        this.entries = [response.data];
        this.model = response.data;
        this.isLoading = false;
      }
    }
  },
  mounted() {
    this.setInitialValue(this.initialValue);
  },
  watch: {
    initialValue (value) {
      this.setInitialValue(value);
    },
    model(value) {
      this.$emit('input', value);
    },
    // value(value) {
    //   this.setValue(value);
    // },
    search: function () {
      this.isLoading = true;

      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        const params = {
          'query': this.search,
          'companyId': this.$route.params.companyId,
        };
        Axios.get("/stores", {
          params: params,
        }).then(function (response) {
          this.entries = response.data;
        }.bind(this)).finally(() => (this.isLoading = false));
      }, 500);
    },
  },
}
</script>

<style scoped>

</style>