<template>
  <v-dialog width="538" v-model="copyDialog" @click:outside="closeCopy">
    <template>
      <v-card class="card-shadow bg-gradient-success">
        <div
          class="card-header-padding d-flex align-center justify-space-between pb-10"
        >
          <v-card-title class="pa-0 text-h3 font-weight-600 text-white">
            確認
          </v-card-title>
          <v-btn
            icon
            @click="closeCopy"
            elevation="0"
            :ripple="false"
            height="38"
            min-width="38"
            width="38"
            class="font-weight-600 text-capitalize btn-ls btn-hover rounded-sm"
            color="rgba(255,255,255,.6)"
          >
            <v-icon size="13">fas fa-times</v-icon>
          </v-btn>
        </div>
        <v-card-text class="card-padding pt-6 text-center">
          <v-icon size="48" class="text-white"> ni-bell-55 </v-icon>
          <h4 class="heading mt-6 text-white font-weight-600 text-uppercase">
            記事ID
            <template v-for="(s, index) in selected">
              <template v-if="index > 0">, </template>
              {{ s.ArticleId }} </template
            >を複製しようとしています
          </h4>
          <p class="text-white font-size-root font-weight-thin mt-3">
            記事に紐付く情報も全て複製されます。<br />
            本当に複製してよろしいですか？
          </p>
        </v-card-text>
        <div class="card-padding d-flex align-center justify-space-between">
          <v-btn
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize btn-secondary py-3 px-6 rounded-sm"
            color="#fff"
            @click="$emit('copyArticle')"
            :loading="copyLoading"
          >
            はい、複製します
          </v-btn>
          <v-btn
            @click="closeCopy"
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize text-white py-3 px-6 rounded-sm"
            color="transparent"
          >
            閉じる
          </v-btn>
        </div>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "copyModal",
  props: {
    copyDialog: {
      type: String,
      default: "",
    },
    copyLoading: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["copyArticle", "closeCopyDialog"],

  methods: {
    closeCopy() {
      this.$emit("closeCopyDialog");
    },
  },
};
</script>

<style scoped></style>
