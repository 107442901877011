<template>
  <v-app-bar
    :color="background"
    dark
    height="auto"
    class="py-4 px-2 toolbar-content-padding-y-none"
    :class="{ 'border-bottom': !hasBg, 'border-bottom-dark': hasBg }"
    flat
  >
    <v-row>
      <v-col cols="12" sm="6">

      </v-col>

      <v-col cols="12" sm="6" class="text-right py-0 my-0">
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-600 text-capitalize drawer-toggler py-3 px-6 rounded-sm"
          :class="{
            'btn-dark-toggler-hover': !hasBg,
            'btn-toggler-hover': hasBg,
            active: togglerActive,
          }"
          v-if="$vuetify.breakpoint.mobile"
          color="transparent"
          @click="drawerClose"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
            <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
            <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
          </div>
        </v-btn>

        <v-menu transition="slide-y-transition" offset-x offset-y right max-width="196">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              :ripple="false"
              class="ls-0 text-capitalize pe-0"
              color="transparent"
              v-bind="attrs"
              v-on="on"
            >
              <span class="text--secondary">{{ getLoginName }}</span>
              <v-icon color="black" class="ml-2">fa-caret-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              link
              :ripple="false"
              @click="appLogout"
              class="min-height-auto"
            >
              <v-list-item-avatar
                class="my-0 me-5"
                width="15"
                min-width="15"
                height="37"
              >
                <v-icon class="text-darker">ni-user-run</v-icon>
              </v-list-item-avatar>

              <v-list-item-content class="pa-0">
                <v-list-item-title class="text-darker">ログアウト</v-list-item-title>
              </v-list-item-content>

              <div class="border-bottom"></div>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "app-bar",
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: String,
  },
  data() {
    return {
      drawer: false,
      togglerActive: false,
    };
  },
  methods: {
    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", true);
    },
    appLogout() {
      this.logout();
      axios.get("/auth/logout").then(() => {
        this.$router.push({ name: "Login" });
        this.openSnackbarSuccess("ログアウトしました");
      });
    },

    ...mapActions("loginUser", ["logout"]),
    ...mapActions("snackbar", ["openSnackbarSuccess"]),
    ...mapGetters("loginUser", ["getLoginUser"]),
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val;
    },
  },
  computed: {
    getLoginName() {
      return this.getLoginUser().CompanyName + " " + this.getLoginUser().Username
    }
  }
};
</script>
