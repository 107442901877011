<template>
  <v-container fluid class="pt-0 px-6 pb-16" :class="{ 'bg-primary': primary }">
    <v-row no-gutters class="py-3">
      <v-col cols="12" md="6" class="d-flex align-center">
        <v-breadcrumbs>
          <v-breadcrumbs-item :to="getPath()" active-class="active-breadcrumb">
            <v-icon>fas fa-home</v-icon>
          </v-breadcrumbs-item>
          <li
            class="v-breadcrumbs__divider opacity-5"
            :class="{'text-muted': !primary }"
          >
            -
          </li>
          <v-breadcrumbs-item
              active-class="active-breadcrumb"
              class="no-default-hover"
              :class="{
              'breadcrumb-alternative': primary,
              }"
              :to="$route.meta.groupPath"
          >
            {{ $route.meta.groupName }}
          </v-breadcrumbs-item>
          <li
            class="v-breadcrumbs__divider opacity-5"
          >
            -
          </li>
          <v-breadcrumbs-item
            active-class="active-breadcrumb"
            class="no-default-hover"
            :class="{
              'breadcrumb-alternative': primary,
            }"
            :to="$route.meta.path"
          >
            <template v-if="$route.name === 'Dashboard'">Default</template>
            <template v-else>{{ $route.meta.displayName }}</template>
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </v-col>
    </v-row>

    <slot></slot>
  </v-container>
</template>
<script>
export default {
  name: "header-top",
  props: {
    primary: Boolean,
    customRightContent: Boolean,
    isAdmin: {
      type: Boolean,
      default: false,
    }
  },
  methods : {
    getPath() {
      // システム管理者用とパスの分岐
      if (this.isAdmin) {
        return "/admin";
      } else {
        return '/' + this.$route.params.companyId + '/admin';
      }
    }
  },
  data() {
    return {
      colorActive: this.primary ? "#fff" : "#5e72e4",
    };
  },
};
</script>
