export default {
    info: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote','link'],
      
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
      
        [{ 'size': ['small', false, 'large', 'huge',] }], // custom dropdown
      
        [{ 'color': [
          '#000000', '#ff0000', '#ff9900', '#ffff00', '#008a00', 
          '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', 
          '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff'] }, { 'background': [] }], // dropdown with defaults from theme
        [{ 'font': ['Segoe UI'] }],
        [{ 'align': [] }],
      
        ['clean'] // remove formatting button
        
      ],
}
