const getters = {};

const state = {
    title: "",
    text: "",
    snackbar: false,
    color: "",
};

const mutations = {
    setSnackbar: (state, data) => {
        state.snackbar = true;
        state.title = data.title;
        state.text = data.text;
        state.color = data.color;
    },
    unsetSnackbar: () => {
        state.snackbar = false;
        state.text = "";
        state.color = "";
    },
};

const actions = {
    openSnackbarApiError: ({ commit }) => {
        commit("setSnackbar", {
            title: "システムエラー",
            text: "時間をおいて再度操作いただくか、システム管理者へご連絡ください。",
            color:"#fb6340"
        })
    },

    /**
     *
     * @param commit
     * @param {{Message:Array, ItemResultMessage:Array}}text
     */
    openSnackbarValidationError: ({ commit }, text) => {
        let msg = text.Message.join("\n");
        commit("setSnackbar", {
            title: "エラー",
            text: msg,
            color: "#fb6340"
        })
    },
    openSnackbarSuccess: ({ commit }, text) => {
        commit("setSnackbar", {
            title: "処理が完了しました",
            text: text,
            color: "green"
        })
    },
    openSnackbarError: ({ commit }, msg) => {
        commit("setSnackbar", {
            title: "エラー",
            text: msg,
            color: "#fb6340"
        })
    },
    openSnackbar: ({ commit }, text) => {
        commit("setSnackbar", text);
    },
    closeSnackbar: ({ commit }) => {
        commit("unsetSnackbar");
    },
};

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions,
};