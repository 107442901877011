/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@fortawesome/fontawesome-free/css/all.css";
import '@mdi/font/css/materialdesignicons.css'

const vuetify = new Vuetify({
  theme: { disable: true },
  icons: {
    iconfont: ["mdi"],
  },
});

Vue.use(Vuetify);

export default new Vuetify({});
