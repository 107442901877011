export default {
    //詳細ページ用
    status: [
        { value: 1, text: "未対応"},
        { value: 7, text: "連絡不通"},
        { value: 2, text: "連絡済"},
        { value: 3, text: "面接待ち"},
        { value: 8, text: "選考（面接）辞退"},
        { value: 9, text: "内定"},
        { value: 10, text: "入社辞退"},
        { value: 4, text: "採用"},
        { value: 5, text: "不採用"},
        { value: 6, text: "入社済み"},
    ],
    //セレクトボックス用
    statusSelect: [
        { value: 0, text: "すべてのステータス", color: "teal--text" },
        { value: 1, text: "未対応", color: "teal--text" },
        { value: 7, text: "連絡不通", color: "red--text" },
        { value: 2, text: "連絡済", color: "orange--text" },
        { value: 3, text: "面接待ち", color: "green--text" },
        { value: 8, text: "選考（面接）辞退", color: "red--text" },
        { value: 9, text: "内定", color: "blue--text"},
        { value: 10, text: "入社辞退", color: "red--text" },
        { value: 4, text: "採用", color: "blue--text" },
        { value: 5, text: "不採用", color: "red--text" },
        { value: 6, text: "入社済み", color: "blue--text" },
    ],
    //一覧画面表示用　(key値がvalueと対応しているversion)
    statusList: [
        { value: 0, text: "すべてのステータス", color: "teal--text" },
        { value: 1, text: "未対応", color: "teal--text" },
        { value: 2, text: "連絡済", color: "orange--text" },
        { value: 3, text: "面接待ち", color: "green--text" },
        { value: 4, text: "採用", color: "blue--text" },
        { value: 5, text: "不採用", color: "red--text" },
        { value: 6, text: "入社済み", color: "blue--text" },
        { value: 7, text: "連絡不通", color: "red--text"},       
        { value: 8, text: "選考（面接）辞退", color: "red--text" },
        { value: 9, text: "内定", color: "blue--text" },
        { value: 10, text: "入社辞退", color: "red--text" },
    ],
}
