export function arrayToTsv(tsvData) {
  let tsv = "";
  for (let row = 0; row < tsvData.length; row++) {
    let keysAmount = Object.keys(tsvData[row]).length;
    let keysCounter = 0;
    if (row === 0) {
      for (let key in tsvData[row]) {
        tsv += key + (keysCounter + 1 < keysAmount ? "\t" : "\n");
        keysCounter++;
      }
    } else {
      for (let key in tsvData[row]) {
        tsv += tsvData[row][key] + (keysCounter + 1 < keysAmount ? "\t" : "\n");
        keysCounter++;
      }
    }
    keysCounter = 0;
  }
  return tsv;
}
