<template>
  <div>
  <header-top-dashboard primary>
  </header-top-dashboard>
  <v-container fluid class="pt-0 px-6 mt-n13">
    <v-row>
      <v-col class="mt-n3">
        <v-card class="card-shadow mb-6">
          <div class="card-header-padding card-border-bottom">
            <div class="font-weight-600 text-h3 text-typo mb-1">
              <span>求人掲載一括アップロード</span>
            </div>
          </div>

          <v-card-text class="card-padding">
            <v-row>
              <v-col cols="12" md="8">
                <p class="font-size-root text-body font-weight-thin">
                  求人掲載を一括更新します。<br>
                  予め求人掲載一覧画面の右下にあるエクスポートボタンからTSVをダウンロードして編集しテキストエリアに貼り付けてください。<br>
                  各アップロード仕様を十分に理解してからご利用ください。意図せずデータを壊すリスクがあります。<br>
                </p>
              </v-col>
            </v-row>
            <div class="border-bottom my-6"></div>

            <v-form class="mt-13">
              <v-row>
                <v-col cols="12" md="12" class="py-0">
                  <v-alert
                      v-if="errorMessageArticle"
                      text
                      outlined
                      color="deep-orange"
                      icon="mdi-fire"
                      class="alert"
                  >
                    {{errorMessageArticle}}
                  </v-alert>
                  <label
                      class="label-color font-weight-600 mb-2 d-block"
                  ><span class="red--text">* </span>アップロードデータ（求人掲載）</label
                  >
                  <v-alert
                      border="left"
                      colored-border
                      type="warning"
                      color="orange"
                  >
                    記事IDを空行にすると新規行が作成されます。<br>
                    記事の削除処理はできません。<br>
                  </v-alert>
                  <v-textarea
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="例）TSVをヘッダー行含めここに貼り付けてください。"
                      v-model="UploadArticleData"
                      required
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <div class="text-right">
                <v-btn @click="submitArticle"
                       elevation="0"
                       :ripple="false"
                       height="43"
                       class="font-weight-600 text-capitalize btn-primary mt-1 py-3 px-6 rounded-sm me-2"
                       color="green"
                       :loading="loading"
                       large
                >求人をアップロード</v-btn
                >
              </div>
              <v-row class="mt-6">
                <v-col cols="12" md="12" class="py-0">
                  <v-alert
                      v-if="errorMessageWhatKind"
                      text
                      outlined
                      color="deep-orange"
                      icon="mdi-fire"
                      class="alert"
                  >
                    {{errorMessageWhatKind}}
                  </v-alert>
                  <label
                      class="label-color font-weight-600 mb-2 d-block"
                  ><span class="red--text">* </span>アップロードデータ（どんな会社でどんな仕事を）</label
                  >
                  <v-alert
                      border="left"
                      colored-border
                      type="warning"
                      color="orange"
                  >
                    記事IDは必須項目です。<br>
                    同一の記事IDに紐付くデータは、アップロードデータで洗い替えされるので注意してください。一部分の更新はできません。<br>
                    一つの記事に対して、必ず完全なデータを設定してください。<br>
                  </v-alert>
                  <v-textarea
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="例）TSVをヘッダー行含めここに貼り付けてください。"
                      v-model="UploadWhatKindData"
                      required
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <div class="text-right">
                <v-btn @click="submitWhatKind"
                       elevation="0"
                       :ripple="false"
                       height="43"
                       class="font-weight-600 text-capitalize btn-primary mt-1 py-3 px-6 rounded-sm me-2"
                       color="green"
                       :loading="loadingWhatKind"
                       large
                >どんな会社でどんな仕事をアップロード</v-btn
                >
              </div>
              <v-row class="mt-6">
                <v-col cols="12" md="12" class="py-0">
                  <v-alert
                      v-if="errorMessageInterview"
                      text
                      outlined
                      color="deep-orange"
                      icon="mdi-fire"
                      class="alert"
                  >
                    {{errorMessageInterview}}
                  </v-alert>
                  <label
                      class="label-color font-weight-600 mb-2 d-block"
                  ><span class="red--text">* </span>アップロードデータ（インタビュー）</label
                  >
                  <v-alert
                      border="left"
                      colored-border
                      type="warning"
                      color="orange"
                      elevation="2"
                  >
                    記事IDは必須項目です。<br>
                    同一の記事IDに紐付くデータは、アップロードデータで洗い替えされるので注意してください。一部分の更新はできません。<br>
                    一つの記事に対して、必ず完全なデータを設定してください。<br>
                  </v-alert>
                  <v-textarea
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="例）TSVをヘッダー行含めここに貼り付けてください。"
                      v-model="UploadArticleInterviewData"
                      required
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <div class="text-right">
                <v-btn @click="submitArticleInterview"
                       elevation="0"
                       :ripple="false"
                       height="43"
                       class="font-weight-600 text-capitalize btn-primary mt-1 py-3 px-6 rounded-sm me-2"
                       color="green"
                       :loading="loadingInterview"
                       large
                >インタビューをアップロード</v-btn
                >
              </div>
              <!-- <v-row class="mt-6">
                <v-col cols="12" md="12" class="py-0">
                  <v-alert
                      v-if="errorMessageQa"
                      text
                      outlined
                      color="deep-orange"
                      icon="mdi-fire"
                      class="alert"
                  >
                    {{errorMessageQa}}
                  </v-alert>
                  <label
                      class="label-color font-weight-600 mb-2 d-block"
                  ><span class="red--text">* </span>アップロードデータ（QA）</label
                  >
                  <v-alert
                      border="left"
                      colored-border
                      type="warning"
                      color="orange"
                      elevation="2"
                  >
                    インタビューIDは必須項目です。<br>
                    同一のインタビューIDに紐付くデータは、アップロードデータで洗い替えされるので注意してください。一部分の更新はできません。<br>
                    一つのインタビューに対して、必ず完全なデータを設定してください。<br>
                  </v-alert>
                  <v-textarea
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="例）TSVをヘッダー行含めここに貼り付けてください。"
                      v-model="UploadQaData"
                      required
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <div class="text-right">
                <v-btn @click="submitQa"
                       elevation="0"
                       :ripple="false"
                       height="43"
                       class="font-weight-600 text-capitalize btn-primary mt-1 py-3 px-6 rounded-sm me-2"
                       color="green"
                       :loading="loadingQa"
                       large
                >QAをアップロード</v-btn
                >
              </div> -->
              <v-row class="mt-6">
                <v-col cols="12" md="12" class="py-0">
                  <v-alert
                      v-if="errorMessageKeyToPopular"
                      text
                      outlined
                      color="deep-orange"
                      icon="mdi-fire"
                      class="alert"
                  >
                    {{errorMessageKeyToPopular}}
                  </v-alert>
                  <label
                      class="label-color font-weight-600 mb-2 d-block"
                  ><span class="red--text">* </span>アップロードデータ（人気の秘訣）</label
                  >
                  <v-alert
                      border="left"
                      colored-border
                      type="warning"
                      color="orange"
                      elevation="2"
                  >
                    記事IDは必須項目です。<br>
                    同一の記事IDに紐付くデータは、アップロードデータで洗い替えされるので注意してください。一部分の更新はできません。<br>
                    一つの記事に対して、必ず完全なデータを設定してください。<br>
                  </v-alert>
                  <v-textarea
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="例）TSVをヘッダー行含めここに貼り付けてください。"
                      v-model="UploadKeyToPopularData"
                      required
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <div class="text-right">
                <v-btn @click="submitKeyToPopular"
                       elevation="0"
                       :ripple="false"
                       height="43"
                       class="font-weight-600 text-capitalize btn-primary mt-1 py-3 px-6 rounded-sm me-2"
                       color="green"
                       :loading="loadingKeyToPopular"
                       large
                >人気の秘訣をアップロード</v-btn
                >
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>
<style scoped>
/* これを設定 */
.alert {
  white-space: pre-wrap;
}
</style>
<script>
import {validationMixin} from "vuelidate";
import axios from "axios";
import {mapActions} from "vuex";
import HeaderTopDashboard from "../../../components/HeaderTopDashboard";

export default {
  name: "ArticleBulkUpdate",
  components: {HeaderTopDashboard},
  data() {
    return {
      UploadArticleData: "",
      UploadWhatKindData: "",
      UploadArticleInterviewData: "",
      UploadQaData: "",
      UploadKeyToPopularData: "",
      errorMessageArticle: "",
      errorMessageWhatKind: "",
      errorMessageInterview: "",
      errorMessageQa: "",
      errorMessageKeyToPopular: "",
      loading: false,
      loadingWhatKind: false,
      loadingInterview: false,
      loadingQa: false,
      loadingKeyToPopular: false,
    }
  },
  mixins: [validationMixin],
  methods: {
    submitArticle() {
      this.loading = true;
      axios.post("/articles/batch?companyId=" + this.$route.params.companyId, {
        Data: this.UploadArticleData,
      }).then(() => {
        this.UploadArticleData = "";
        this.errorMessageArticle = "";
        this.openSnackbarSuccess("求人掲載を更新しました");
      }).catch(e => {
        const res = e.response;
        if (res && res.status === 400) {
          this.openSnackbarValidationError({Message: ['バリデーションエラーが発生しました。内容を確認してください'], ItemResultMessage: []});
          this.errorMessageArticle = res.data.Message.join("\n");
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    submitWhatKind() {
      this.loadingWhatKind = true;
      axios.post("/what-kinds/batch?companyId=" + this.$route.params.companyId, {
        Data: this.UploadWhatKindData,
      }).then(() => {
        this.UploadWhatKindData = "";
        this.errorMessageWhatKind = "";
        this.openSnackbarSuccess("どんな会社でどんな仕事を更新しました");
      }).catch(e => {
        const res = e.response;
        if (res && res.status === 400) {
          this.openSnackbarValidationError({Message: ['バリデーションエラーが発生しました。内容を確認してください'], ItemResultMessage: []});
          this.errorMessageWhatKind = res.data.Message.join("\n");
        }
      }).finally(() => {
        this.loadingWhatKind = false;
      });
    },
    submitArticleInterview() {
      this.loadingInterview = true;
      axios.post("/artricle-interviews/batch?companyId=" + this.$route.params.companyId, {
        Data: this.UploadArticleInterviewData,
      }).then(() => {
        this.UploadArticleInterviewData = "";
        this.errorMessageInterview = "";
        this.openSnackbarSuccess("インタビューの紐付けを更新しました");
      }).catch(e => {
        const res = e.response;
        if (res && res.status === 400) {
          this.openSnackbarValidationError({Message: ['バリデーションエラーが発生しました。内容を確認してください'], ItemResultMessage: []});
          this.errorMessageInterview = res.data.Message.join("\n");
        }
      }).finally(() => {
        this.loadingInterview = false;
      });
    },
    submitQa() {
      this.loadingQa = true;
      axios.post("/qas/batch?companyId=" + this.$route.params.companyId, {
        Data: this.UploadQaData,
      }).then(() => {
        this.UploadQaData = "";
        this.errorMessageQa = "";
        this.openSnackbarSuccess("QAを更新しました");
      }).catch(e => {
        const res = e.response;
        if (res && res.status === 400) {
          this.openSnackbarValidationError({Message: ['バリデーションエラーが発生しました。内容を確認してください'], ItemResultMessage: []});
          this.errorMessageQa = res.data.Message.join("\n");
        }
      }).finally(() => {
        this.loadingQa = false;
      });
    },
    submitKeyToPopular() {
      this.loadingKeyToPopular = true;
      axios.post("/key-to-populars/batch?companyId=" + this.$route.params.companyId, {
        Data: this.UploadKeyToPopularData,
      }).then(() => {
        this.UploadKeyToPopularData = "";
        this.errorMessageKeyToPopular = "";
        this.openSnackbarSuccess("人気の秘訣を更新しました");
      }).catch(e => {
        const res = e.response;
        if (res && res.status === 400) {
          this.openSnackbarValidationError({Message: ['バリデーションエラーが発生しました。内容を確認してください'], ItemResultMessage: []});
          this.errorMessageKeyToPopular = res.data.Message.join("\n");
        }
      }).finally(() => {
        this.loadingKeyToPopular = false;
      });
    },
    ...mapActions("snackbar", ["openSnackbarSuccess", "openSnackbarApiError", "openSnackbarValidationError"]),
  },
}
</script>

<style scoped>

</style>