import Quill from 'quill';

const Block = Quill.import('blots/block');
const Inline = Quill.import('blots/inline');

// font,divタグ表示　
//TODO:table,dlは記述しているものの表示はうまくいっていない 
class DivBlot extends Inline {}
  DivBlot.blotName = 'div';
  DivBlot.tagName = 'div';

class FontBlot extends Inline {}
  FontBlot.blotName = 'font';
  FontBlot.tagName = 'font';

class TableBlot extends Block {}
  TableBlot.blotName = 'table';
  TableBlot.tagName = 'table';

class TrBlot extends Block {}
  TrBlot.blotName = 'tr';
  TrBlot.tagName = 'tr';

class TdBlot extends Block {}
  TdBlot.blotName = 'td';
  TdBlot.tagName = 'td';

class DlBlot extends Block {}
  DlBlot.blotName = 'dl';
  DlBlot.tagName = 'dl';

class DtBlot extends Block {}
  DtBlot.blotName = 'dt';
  DtBlot.tagName = 'dt';

class DdBlot extends Block {}
  DdBlot.blotName = 'dd';
  DdBlot.tagName = 'dd';

Quill.register({
  'formats/div': DivBlot,
  'formats/font': FontBlot,
  'formats/table': TableBlot,
  'formats/tr': TrBlot,
  'formats/td': TdBlot,
  'formats/dl': DlBlot,
  'formats/dt': DtBlot,
  'formats/dd': DdBlot
});